@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

:root {
  --primary: #043cad;
  --white: #fff;
  --heading: #343c4d;
  --secondary-subtitle: #7c868e;
  --tertiary: #04acda;
  --primary-gradiant: linear-gradient(90deg, #0770d3 0%, #04acda 100%);
  --green: #0cd3c3;
  --light-blue: #e6ecf7;
  --secondary-light: #abb5be;
  --beerus: rgba(235, 235, 235, 1);
  --text-primary: #343c4d;
  --light-hover: rgba(0, 0, 0, 0.08);
  --secondary-light: rgba(171, 181, 190, 1);
  --card: #d1e6ff;
  --light-trunks: rgba(153, 156, 160, 1);
  --dark: #000;
  --shadow1: 0px 4px 10px 0px rgba(0, 0, 0, 0.12);
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Montserrat", sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
  height: 5px;
  border-radius: 100px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #abb5be;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 100px;
}

a {
  text-decoration: none;
  transition: all 0.3s;
}

.pricing {
  display: flex;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  font-weight: 700;
  font-size: 10px;
  transition: all 0.3s;
  line-height: 18px;
  border: none;
}

.map-container {
  height: 220px;
}

.btnPrimary {
  background: linear-gradient(90deg, #3151a4 0%, #4dc3d2 100%);
  color: #ffffff;
}

.btnPrimary:hover {
  color: #ffffff;
  background: linear-gradient(90deg, #4dc3d2 0%, #3151a4 100%);
}

.btnSecondary {
  background: var(--light-blue);
  color: var(--primary);
  border-color: var(--light-blue);
}

.btnSecondary:hover {
  background: var(--light-blue);
  color: var(--primary);
  border-color: var(--light-blue);
}

.btnSecondary span {
  background: rgba(7, 112, 211, 1), rgba(4, 172, 218, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.error-field {
  color: red !important;
  font-size: 11px !important;
  margin-top: 4px !important;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Header  */
.header {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 999;
}

.topbar {
  background: var(--primary);
  padding: 12px 0;
}

.topbar .address {
  display: inline-flex;
  align-items: center;
}

.topbar .address span {
  font-style: normal;
  font-weight: 400;
  font-size: 13.3px;
  line-height: 20px;
  color: var(--white);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.topbar .address i {
  align-items: center;
  line-height: 0;
  margin-right: 8px;
  display: inline-flex;
  color: white;
}

.appLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-right: 20px;
}

.appLinks p {
  margin: 0;
  color: var(--white);
  font-size: 12px;
  font-family: "DM Sans", sans-serif;
}

.contactLinks {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 13.3px;
  line-height: 22px;
  color: var(--white);
  gap: 16px;
}

.contactLinks a {
  color: var(--white);
  display: inline-flex;
  align-items: center;
  line-height: 1;
  font-size: 12px;
  font-family: "DM Sans", sans-serif;
}

.contactLinks a i {
  line-height: 0;
  margin-right: 5px;
  font-size: 18px;
}

.appLinks a img {
  height: 24px;
}

header .btnPrimary {
  font-weight: 500;
  font-size: 12px;
  padding: 8px 19px;
  height: 40px;
}

.navbar-brand {
  margin: 0 !important;
  display: inline-flex;
}

header .btnPrimary i {
  margin-right: 8px;
  line-height: 1;
  font-size: 24px;
}

header nav {
  height: 70px;
}

/* Services */

.wrapperMain {
  min-height: calc(100vh - 119px);
  background: var(--tertiary);
  position: relative;
  overflow: hidden;
  padding: 36px 0;
}

.wrapperMain .z10 {
  z-index: 5;
}

.homeMain {
  padding: 40px 0px 0;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.homeMain.postLoginBG {
  background: url(./assets/images/bgMain.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.homeMain.postLoginBG .circlebg {
  display: none;
}

.flex__1 {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.flex_1.row {
  position: relative;
  z-index: 5;
}

.wrapperMain .container {
  position: relative;
}

.circlebg {
  position: absolute;
  width: 100%;
  height: 80%;
  left: 0;
  right: 0;
  text-align: center;
  top: 0;
  margin: 0 auto;
}

.heroText {
  text-align: center;
  max-width: 525px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
}

.heroText span {
  display: block;
  font-size: 14.55px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.75px;
  letter-spacing: 2.183px;
  background: var(--primary-gradiant);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: "Poppins", sans-serif;
}

.heroText h1 {
  color: #0cd3c3;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  margin: 0;
}

.heroText {
  margin-bottom: 30px;
}

.heroText p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 162%;
  color: var(--light-blue);
  max-width: 668px;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}

.heroText h4 {
  font-weight: 400;
  font-size: 24px;
  line-height: 133%;
  color: var(--light-blue);
  font-family: "Poppins", sans-serif;
}

.heroText h3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 127%;
  color: var(--light-blue);
  font-family: "Poppins", sans-serif;
  margin: 0;
}

.cardMain {
  background: var(--white);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  /* padding: 12px 20px; */
  min-height: 716px;
}

/* Changes */

.download {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding-bottom: 60px;
}

.download .title span {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 34.02px;
  margin-bottom: 15px;
  display: block;
}

.download .title h4 {
  color: #fff;
  font-family: Montserrat;
  font-size: 25.5px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.6px;
  margin-bottom: 8px;
}

.download .downloadLink {
  display: inline-flex;
  align-items: center;
  border-radius: 75px;
  background: #fff;
  box-shadow: -3.75px 4.5px 6.15px 0px rgba(0, 0, 0, 0.25);
  height: 40px;
  border-radius: 100px;
  width: 135px;
  margin-bottom: 8px;
  padding: 7px;
}

.download .downloadLink img {
  max-width: 98px;
}

.download .downloadLink:hover {
  background: #010f62;
}

.download .downloadLink:hover img {
  filter: brightness(0) invert(1);
}

.searchCard {
  border-radius: 15px;
  background: #fff;
  box-shadow: 9px 10.5px 16.425px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin: 0 auto;
  max-width: 900px;
}

/* .cardMain {
  min-height: 519px;
} */
.cardInner {
  padding: 0px 20px;
}

.cardHeader {
  padding: 12px 20px;
  border-bottom: 1px solid var(--beerus);
}

.cardHeader h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  color: var(--primary);
}

.counterWizard {
  display: flex;
  align-items: center;
}

.counterWizard .steps {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: var(--secondary-subtitle);
  margin-right: 12px;
  flex: 0 0 55px;
}

.counterWizard .stepers {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
  margin: 0;
}

.counterWizard .stepers li {
  line-height: 5px;
}

.counterWizard .stepers li span {
  min-width: 103px;
  width: 100%;
  display: inline-block;
  height: 5px;
  background: var(--secondary-light);
  border-radius: 4px;
}

.counterWizard .stepers li.active span {
  background: var(--primary);
}

.cardBody {
  padding: 12px 0 0;
  background: var(--white, #fff);
  border-radius: 0px 0px 12px 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.serviceMain {
  display: flex;
  gap: 10px;
  height: 100%;
}

.media-body {
  flex: 1;
}

.serviceMain .media-body {
  width: calc(100% - 116px);
}

.btn-select {
  width: 100%;
  justify-content: space-between;
  padding: 0.75rem;
  border-radius: 30px;
  border: 1px solid var(--beerus, #ebebeb);
  background: var(--white, #fff);
  height: 48px;
  font-size: 16px;
  color: var(--text-primary);
  font-weight: normal;
}

.selectMenu .dropdown-item span.text,
.btn-select span.text {
  flex: 1;
  text-align: left;
  padding: 0 8px;
  font-weight: normal;
}

.selectMenu .dropdown-item span.icon,
.btn-select span.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.selectMenu .dropdown-item span.icon img,
.btn-select span.icon img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.btn-select:first-child:active,
.btn-select.show:first-child:active:focus-visible,
.btn-select.show:focus,
.btn-select.show,
.btn-select:first-child:active:focus-visible,
.btn-select:active,
.btn-select:hover,
.btn-select:focus {
  border: 1px solid var(--beerus, #ebebeb);
}

.btn-select:after {
  content: none;
}

.btn-select + .dropdown-menu {
  min-width: 100%;
  max-width: 100%;
  border-radius: 12px;
  background: var(--light-gohan, #fff);
  border: none;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4),
    0px 8px 24px -6px rgba(0, 0, 0, 0.16);
  padding: 4px;
  margin-top: 8px;
}

.selectMenu .dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 4px;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
}

.dropdown-item:hover,
.dropdown-item:active {
  background: var(--light-hover);
  color: var(--text-primary);
}

.categoriesTabs {
  padding: 10px 0 0;
  width: 100%;
}

.categoriesTabs .swiper-slide {
  height: initial;
}

.categoriesTabs .swiper-slide button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  border: none;
  min-width: 100%;
  border-radius: 8px;
  padding: 4px 8px;
  height: 100%;
}

.categoriesTabs .swiper-slide button h3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: var(--secondary-subtitle);
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.categoriesTabs .swiper-slide button p {
  font-size: 10px;
  color: var(--secondary-subtitle);
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.categoriesTabs .swiper-slide button:hover,
.categoriesTabs .swiper-slide button.active {
  background: var(--card);
}

.categoriesTabs .swiper-slide button:hover h3,
.categoriesTabs .swiper-slide button.active h3 {
  color: var(--heading);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.categoriesTabs .swiper-slide button:hover p,
.categoriesTabs .swiper-slide button.active p {
  color: var(--primary);
}

.tabContent-header {
  padding: 8px 0;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--beerus);
}

.tabContent-header h3 {
  color: var(--headings);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tabContent-header p {
  font-weight: 600;
  line-height: 20px;
  color: var(--primary);
  margin: 0;
}

.tabContentBody h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--heading);
  margin-bottom: 8px;
}

.tabContentBody p {
  color: var(--secondary-subtitles);
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0.8rem;
}

.cardFooter {
  display: flex;
  justify-content: flex-end;
}

.cardFooter .btn {
  max-width: 236px;
  font-size: 14px;
  height: 40px;
  text-transform: uppercase;
  width: 100%;
}

.cardFooter .btn.btnIcon {
  justify-content: space-between;
  border: none;
}

.cardFooter .btnIcon span {
  text-align: center;
  flex: 1;
}

.sliderWrapper {
  flex: 0 0 116px;
  max-width: 116px;
}

.cardTypes {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--secondary-subtitle);
  border-radius: 12px;
  padding: 8px 4px;
  cursor: pointer;
}

.cardTypes.active {
  background-color: var(--card);
  border: 1px solid var(--primary);
}

.cardTypes .icon {
  margin: 0 auto 4px;
  width: 24px;
  height: 24px;
}

.cardTypes .icon img {
  max-width: 100%;

  max-height: 100%;
  object-fit: cover;
}

.cardTypes h4 {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: var(--heading);
  margin: 0 0 4px;
}

.cardTypes p {
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
  color: var(--primary);
  margin: 0;
}

.cardTypes .price {
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
}

.custom-navigation {
  background: transparent;
  border: none;
  color: var(--secondary-light);
  padding: 0;
}

.tabContentBody {
  height: 338px;
  overflow-y: auto;
  padding: 10px;
  padding-right: 20px;
  background: #f4f9ff;
  border-radius: 8px;
}

.cardFooter {
  padding: 0px 20px 12px;
  display: flex;
  gap: 7px;
}

.sliderHolder {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow-x: hidden;
  padding-right: 20px;
}

.flex-1 {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.title h3 {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--primary);
  padding-bottom: 12px;
  border-bottom: 1px solid var(--secondary-light);
  margin-bottom: 12px;
}

/* FORMS */
label {
  font-size: 14px;
  line-height: 22px;
  color: var(--heading);
  margin-bottom: 8px;
}

.btn-select + .searchDropDown {
  border-radius: 12px;
  background: var(--light-gohan, #fff);
  padding: 8px 0;
  border: none;
  box-shadow: var(--shadow1);
  top: 0 !important;
  margin: 0 !important;
  transform: inherit !important;
}

.searchDropDown .search {
  position: relative;
}

.searchDropDown .search i {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 15px;
  line-height: 0;
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--light-trunks);
}

.searchDropDown .search .form-control {
  box-shadow: none;
  border: none;
  border-bottom: 1px solid var(--beerus);
  padding-left: 44px;
  border-radius: 0;
}

.searchDropDown ul {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: white;
}

.searchDropDown ul li .dropdown-item {
  display: flex;
  align-items: center;
  padding: 8px 4px;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
}

.searchDropDown ul li .dropdown-item span.text {
  flex: 1;
  text-align: left;
  color: var(--headings, #343c4d);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.searchDropDown ul li .dropdown-item span.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.searchDropDown ul li .dropdown-item span.radio {
  display: inline-flex;
  align-items: center;
  width: 12px;
  height: 12px;
  border: 1px solid var(--light-trunks);
  border-radius: 50%;
  margin-right: 8px;
}

.dropdown-item.active,
.dropdown-item:active {
  background: var(--card);
  color: var(--text-primary);
}

.footerDropdown {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 7px;
  padding: 12px 12px 4px;
  border-top: 1px solid var(--beerus);
}

.footerDropdown .btn {
  min-width: 109px;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1;
  height: 32px;
}

.footerDropdown .btn.btnIcon {
  justify-content: space-between;
}

.footerDropdown .btn.btnIcon span {
  flex: 1;
}

.searchDropDown ul li .dropdown-item.active .radio {
  background: var(--primary);
  border-color: var(--primary);
  box-shadow: inset 0 0 0 2px var(--white);
}

.searchDropDown ul li .dropdown-item:hover {
  background: var(--card);
}

.formGroup {
  margin-bottom: 20px;
}

.numberFields {
  flex-wrap: nowrap;
  align-items: center;
}

.numberFields .col {
  flex: 1;
}

.numberFields span {
  width: auto;
  padding: 0;
}

.numberFields .form-control {
  border-radius: 30px;
  padding: 8px 16px;
  background-color: var(--card);
  font-size: 14px;
}

.numberFields .form-control::placeholder {
  color: #ebebeb;
}

.colorPickerRow {
  align-items: center;
}

.colorPickerRow .colorPicker {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.selectedColor {
  align-items: center;
  justify-content: flex-end;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: none;
}

.selectedColor.expanded {
  display: inline-flex;
  box-shadow: inset 0 0 0px 6px rgba(255, 255, 255, 1);
}

.selectedColor span,
.colorPickerRow .colorPicker span {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  background: var(--dark);
  border-radius: 50%;
  cursor: pointer;
}

.colorPickerRow .colorPicker span.active {
  background-color: black;
  box-shadow: inset 0 0 0px 6px rgba(255, 255, 255, 1);
}

.colorPickerRow label {
  margin-bottom: 0;
}

input.color {
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  position: absolute;
}

.search-form {
  position: relative;
}

.form-control {
  border-radius: 30px;
  background: var(--card);
  overflow: hidden;
  color: var(--secondary-text);
  font-size: 12px;
  line-height: 22px;
}

.search-form .form-control {
  padding-left: 48px;
}

.form-control:focus {
  box-shadow: none;
}

.search-form img {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 16px;
}

.mapCard {
  margin: 12px 0;
}

.roundedLabel + label {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(124, 134, 142, 0.5);
  background: var(--white);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  padding: 8px;
  border-radius: 32px;
  height: 32px;
  cursor: pointer;
}

.roundedLabel:checked + label {
  border: 1px solid var(--secondary, #033cad);
  background: var(--light-blue, #e6ecf7);
}

.react-datepicker {
  display: flex;
  font-family: "Poppins", sans-serif;
}

.react-datepicker__month-container {
  flex: 1;
}

.react-datepicker__day-names,
.react-datepicker__month {
  display: flex;
  flex-wrap: wrap;
}

.react-datepicker__time-container,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 150px;
}

.react-datepicker__navigation--next--with-time:not(
    .react-datepicker__navigation--next--with-today-button
  ) {
  right: 148px;
}

.react-datepicker__header {
  background: transparent;
  border: none;
}

.react-datepicker {
  border-radius: 16px 0 0 16px;
  border: 1px solid var(--beerus);
}

.react-datepicker__time-container {
  border-color: var(--beerus);
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  text-align: left;
  font-size: 14px;
}

.react-datepicker-time__header {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
}

.react-datepicker__current-month {
  font-weight: 600;
  font-size: 14px;
}

.react-datepicker__month {
  font-size: 14px;
}

.react-datepicker__day-name,
.react-datepicker__time-name {
  font-size: 14px;
  font-weight: 600;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__day {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50%;
  background: var(--primary);
  font-weight: 400;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.react-datepicker__day:hover {
  border-radius: 50%;
  background: var(--primary);
  color: #fff;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  height: calc(262px + (1.7rem / 2));
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: var(--light-blue);
  color: var(--primary);
  font-weight: 400;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: var(--light-blue);
  color: var(--primary);
}

.stepsMain {
  flex: 1;
}

.customModal .modal-dialog {
  max-width: 610px;
}

.customModal .modal-content {
  border-radius: 12px;
  background: linear-gradient(90deg, #0770d3 0%, #04acda 100%);
  box-shadow: 0px 8px 35px 0px rgba(0, 0, 0, 0.16);
  border: none;
}

.customModal .modal-content .modal-body {
  padding: 60px 30px;
  border: none;
}

.btn-btnIcon {
  position: absolute;
  top: 16px;
  right: 20px;
  background: var(--primary);
  border-radius: 30px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: var(--white);
  padding: 0;
}

.customModal .form-card {
  text-align: center;
  max-width: 356px;
  margin: 0 auto;
}

.customModal .form-card h3 {
  color: var(--white);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.customModal .form-card p {
  color: var(--white);
  font-size: 12px;
  line-height: 20px;
}

.customModal .form-card .react-tel-input .form-control {
  width: 100%;
  border-radius: 30px;
  background: var(--card);
  overflow: hidden;
  color: var(--secondary-text);
  font-size: 12px;
  line-height: 22px;
  padding: 8px 1rem;
  height: 40px;
}

.customModal .form-card .react-tel-input .flag-dropdown {
  position: static;
  width: 70px;
  border-radius: 30px;
  margin-right: 8px;
}

.customModal .form-card .react-tel-input .flag-dropdown.open .selected-flag,
.customModal .form-card .react-tel-input .selected-flag {
  border-radius: 30px;
}

.customModal .form-card .react-tel-input .flag-dropdown.open .selected-flag,
.customModal .form-card .react-tel-input .selected-flag {
  width: auto;
}

.customModal .form-card .react-tel-input {
  display: flex;
  flex-direction: row-reverse;
}

.react-tel-input * {
  text-align: left;
}

.selected-flag .arrow {
  margin-left: auto;
}

.form-card .btn {
  height: 40px;
  font-size: 14px;
}

.form-card .btnSecondary i {
  margin-left: 5px;
}

.form-card .or {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.form-card .or:after,
.form-card .or:before {
  content: "";
  width: 36.5px;
  height: 1px;
  display: inline-flex;
  background: var(--primary);
}

.form-card .or span {
  margin: 0 8px;
}

.socialLogin {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  background: var(--white, #fff);
  box-shadow: var(--shadow1);
  height: 40px;
  color: var(--heading);
  font-weight: 600;
  line-height: 24px;
  font-size: 14px;
}

.socialLogin i {
  margin-right: 10px;
  line-height: 0;
}

.optFields {
  display: flex;
  gap: 8px;
}

.optFields .form-control {
  height: 56px;
  border-radius: 8px;
  border: 1px solid var(--light-beerus-beerus, #ebebeb);
  background: var(--light-gohan, #fff);
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
}

.form-card .note {
  text-align: center;
  color: var(--light-blue, #e6ecf7);
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
}

.cartLink {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: var(--heading);
}

.cartLink span {
  border-radius: 20px;
  background: var(
    --primary-gradient,
    linear-gradient(90deg, #0770d3 0%, #04acda 100%)
  );
  color: var(--default-white, #fff);
  text-align: center;
  font-size: 8px;
  font-weight: 500;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -3px;
  right: -8px;
}

.rightLinks {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.rightLinks li {
  margin-left: 25px;
}

.rightLinks li:first-of-type .dropdown button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

.btn-user {
  padding: 0;
  border: none;
  display: inline-flex;
  align-items: center;
  margin: 0;
}

.btn-user .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  position: relative;
}

.btn-user .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.btn-user .avatar .status {
  width: 10px;
  height: 10px;
  background: #72e128;
  border-radius: 50%;
  position: absolute;
  right: 2px;
  bottom: 2px;
  border: 2px solid #fff;
}

.btn-user .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn-user::after {
  content: none;
}

.btn-user .name {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: var(--heading);
  font-family: "DM Sans", sans-serif;
}

.userEmail {
  color: var(--secondary-light-color, #abb5be);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  -webkit-line-clamp: 1;
  overflow: hidden;
  max-width: 140px;
  display: block;
  margin: 0;
}

.btn-user + .dropdown-menu {
  min-width: 230px;
  margin-top: 12px;
  border: none;
  padding: 0;
  border-radius: 10px;
  background: var(--light-background-paper, #fff);
  box-shadow: 0px 3px 14px 2px rgba(76, 78, 100, 0.12),
    0px 8px 10px 1px rgba(76, 78, 100, 0.14),
    0px 5px 5px -3px rgba(76, 78, 100, 0.2);
}

.btn-user + .dropdown-menu .btn-user {
  padding: 16px 16px 6px;
  display: flex;
}

.menuProfile {
  padding: 10px 0;
  list-style: none;
  margin: 0;
}

.menuProfile li {
  margin: 0;
}

.menuProfile li:hover {
  background-color: var(--light-blue, #e6ecf7);
}

.menuProfile li a {
  color: var(--secondary-text, #54595e);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
}

.menuProfile li button {
  border: none;
  color: var(--secondary-text, #54595e);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.15px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
}

.menuProfile li a i {
  font-size: 24px;
  margin-right: 10px;
  line-height: 0;
}

.navbarMenu .navbar-nav a {
  text-transform: uppercase;
  color: var(--text-colour-2, #2b2b2b);
  font-size: 12px;
  text-transform: uppercase;
  padding: 13px 16px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  border-radius: 100px;
  font-family: "DM Sans", sans-serif;
}

.naveBarMain a.withOutLgn {
  text-transform: uppercase;
  color: var(--text-colour-2, #2b2b2b);
  font-size: 12px;
  text-transform: uppercase;
  padding: 5px 12px !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-family: "DM Sans", sans-serif;
}

.naveBarMain a.withOutLgn.active {
  background: var(--light-blue, #e6ecf7);
}

.naveBarMain a.withOutLgn.active span {
  font-weight: 700;
  background: var(--primary-gradiant);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.navbarMenu .navbar-nav a.active {
  background: var(--light-blue, #e6ecf7);
}

.navbarMenu .navbar-nav a.active span {
  font-weight: 700;
  background: var(--primary-gradiant);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.btn-shoping {
  border: none;
  padding: 0;
}

.btn-shoping:after {
  content: none;
}

.btn-shoping + .dropdown-menu {
  min-width: 512px;
  margin-top: 12px;
  border: none;
  padding: 0;
  border-radius: 10px;
  background: var(--light-background-paper, #fff);
  box-shadow: 0px 3px 14px 2px rgba(76, 78, 100, 0.12),
    0px 8px 10px 1px rgba(76, 78, 100, 0.14),
    0px 5px 5px -3px rgba(76, 78, 100, 0.2);
}

.dropdownTitle {
  padding: 16px 24px;
  border-bottom: 1px solid #ebebeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdownTitle h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
}

.rightLinks .media ul li {
  margin: 0;
}

.cardBody.youcart {
  padding: 12px 24px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 240px;
}

.cardBody.youcart::-webkit-scrollbar {
  display: block;
}

.mediaCard {
  display: flex;
  border-radius: 8px;
  background: var(--white, #fff);
  /* Shadow/Small */
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  position: relative;
  margin: 0 0 16px;
}

.mediaCard .icon {
  width: 59px;
  height: 59px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mediaCard .mediaBody {
  flex: 1;
}

.mediaCard .mediaBody h3 {
  color: var(--headings, #343c4d);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.mediaCard .mediaBody ul li {
  display: flex;
  align-items: center;
  margin: 0;
  line-height: 1;
}

.mediaCard .mediaBody ul li span {
  color: var(--secondary-text, #54595e);
  font-size: 10px;
  line-height: 15px;
  flex: 1;
  padding-left: 5px;
}

.mediaCard .mediaBody ul li span span {
  padding: 0;
}

.mediaCard .btn-close {
  position: absolute;
  top: 9px;
  right: 16px;
  padding: 0;
}

.mediaCard .btn-close:focus {
  box-shadow: none;
}

.mediaEnd {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.mediaEnd .price {
  font-weight: 700;
  line-height: 20px;
  background: var(--primary-gradiant);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.cartFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
}

.cartFooter .ctaRemove {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: var(--light-trunks, #999ca0);
  text-align: center;
  line-height: 24px;
}

.cartFooter .btnPrimary {
  padding: 4px 16px;
  height: 40px;
  font-size: 14px;
}

.cartFooter .btnPrimary i {
  line-height: 0;
  font-size: 14px;
  margin-left: 8px;
}

.cardMain .cardBody {
  padding: 12px 0;
}

.savedTitle h3 {
  border: none;
  margin-bottom: 0;
}

.btn-ellipses {
  padding: 0;
  outline: none;
  border: none !important;
}

.btn-ellipses i {
  font-size: 24px;
}

.btn-ellipses:after {
  content: none;
}

.cardTitle {
  line-height: 1;
  display: flex;
  align-items: center;
}

.cardSaved {
  border-radius: 12px;
  border: 1px solid var(--secondary-subtitles, #7c868e);
  background: #fff;
  padding: 0px 8px 8px 8px;
  cursor: pointer;
  max-height: 91px;
}

.cardSaved .vehicleInfo {
  padding: 5px;
  text-align: center;
}

.cardSaved .vehicleInfo .vehicle {
  display: inline-flex;
  align-items: center;
  color: var(--primary);
  font-weight: 500;
  word-break: break-all;
  font-size: 12px;
}

.cardSaved .vehicleInfo .vehicle span {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;
}

.cardSaved .vehicleInfo .vehicle i {
  margin-right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
}

.cardSaved .vehicleInfo .vehicle i img {
  max-width: 100%;
}

.cardSaved .vehicleInfo .modal-name {
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
}

.sliderHolderHori {
  display: flex;
  align-items: center;
  width: 100%;
}

.dropdown-menu {
  min-width: 137px;
  border-radius: 12px;
  background: var(--light-gohan, #fff);
  border: none;
  box-shadow: var(--shadow1);
}

.btn-ellipses + .dropdown-menu {
  min-width: 137px;
  width: 137px;
  border-radius: 4px;
  background: var(--light-gohan, #fff);
  box-shadow: var(--shadow1);
  transform: inherit !important;
  margin: 0;
  padding: 4px 4px 4px 4px;
  right: -4px !important;
}

.btn-ellipses + .dropdown-menu .dropdown-item {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  padding: 4px;
}

.btn-ellipses + .dropdown-menu .dropdown-item a {
  border-radius: 4px;
}

.sliderHolderHori .swiper {
  padding-bottom: 8px;
}

.cardLocationSaved.selected,
.cardSaved.selected {
  border: 1px solid var(--secondary, #033cad);
  background: var(--light-blue, #e6ecf7);
}

.cardLocationSaved {
  display: flex;
  border-radius: 12px;
  border: 1px solid var(--secondary-subtitles, #7c868e);
  background: #fff;
  padding: 10px 20px;
  cursor: pointer;
}

.cardLocationSaved .media-body {
  flex: 1;
}

.cardLocationSaved .media-body .address {
  display: flex;
  margin-bottom: 10px;
}

.cardLocationSaved .media-body .address i {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: baseline;
  justify-content: center;
  line-height: 0;
  font-size: 20px;
}

.cardLocationSaved .media-body .address:last-of-type {
  margin-bottom: 0;
}

.cardLocationSaved .media-body .address .flex-1 {
  flex: 1;
  font-size: 12px;
  color: var(--secondary-subtitles, #7c868e);
  font-weight: 500;
}

.cardLocationSaved .media-body .address .flex-1 p {
  max-width: 240px;
}

.cardLocationSaved .media-body .address .flex-1.textprimary {
  color: var(--heading) !important;
  font-size: 16px;
  font-weight: 500;
}

.sliderVehicle .swiper {
  padding: 20px 0;
}

.sliderVehicle .dropdown-menu {
  top: -7px !important;
  left: auto !important;
}

.mainWrapper {
  padding: 62px 0;
}

.caption {
  text-align: center;
  color: var(--white);
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 40px;
}

.checkout {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 8px 35px 0px rgba(0, 0, 0, 0.16);
  overflow: hidden;
}

.orderDetail {
  padding: 30px 40px;
  border-radius: 12px;
}

.orderDetail .brandName {
  margin-bottom: 30px;
}

.orderHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.orderHeader h3 {
  margin: 0;
  color: var(--headings, #343c4d);
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.orderHeader .total {
  color: var(--headings, #343c4d);
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.mediaorderCard {
  display: flex;
  margin: 0 0 16px;
}

.orderContentBox {
  border-radius: 8px;
  background: var(--white, #fff);
  /* Shadow/Small */
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  position: relative;
  transition: background 0.3s;
}

.mediaorderCard .icon {
  width: 59px;
  height: 59px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mediaorderCard .icon img {
  width: 32px;
  height: 32px;
}

.vehicleinfo .icon,
.serviceInfo .icon img {
  width: 32px;
  height: 32px;
}

.mediaorderCard .media-body {
  padding: 0 6px;
  flex: 1;
}

.mediaorderCard .media-body h4 {
  color: var(--headings, #343c4d);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 4px;
}

.mediaorderCard .media-body .address {
  display: flex;
  align-items: center;
}

.mediaorderCard .media-body .address .iconLabel {
  color: #0cd3c3;
  margin-right: 6px;
  width: 13px;
  height: 13px;
  font-size: 12px;
  line-height: 1;
}

.mediaorderCard .media-body .address span {
  flex: 1;
  color: var(--secondary-text, #54595e);
  font-size: 10px;
  line-height: 15px;
}

.mediaorderCard .media-body .price {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  background: var(--primary-gradiant);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 4px;
}

.mediaorderCard .btn-remove {
  border: none;
  background: transparent;
  font-size: 24px;
  color: #b22b38;
}

.mediaorderCard .action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.cta-detail {
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  padding: 0;
  color: var(--secondary-text, #54595e);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.cta-detail i {
  line-height: 0;
  font-size: 16px;
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.cta-detail.active i {
  transform: rotate(180deg);
}

.collapseContent h4 {
  color: var(--headings, #343c4d);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0;
}

.collapseContent .titleContent {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.addressInfo,
.vehicleinfo,
.serviceInfo {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 8px;
  margin-bottom: 12px;
}

.serviceInfo {
  flex-direction: column;
  padding: 0;
}

.serviceInfo .media {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px;
}

.vehicleinfo .icon,
.serviceInfo .icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
}

.vehicleinfo .media-body,
.serviceInfo .media-body {
  flex: 1;
  padding: 0 11px;
  font-weight: 500;
  color: var(--headings, #343c4d);
}

.serviceInfo .cta_info {
  display: inline-flex;
  align-items: center;
}

.cta_info .price span {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-transform: uppercase;
  background: var(--primary-gradiant);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-right: 12px;
}

.serviceInfo .cta-desc {
  padding: 0;
  border: none;
  background: transparent;
  font-size: 24px;
  color: #7c868e;
  line-height: 0;
}

.serviceInfo .cta-desc i {
  width: 18px;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
}

.serviceInfo .cta-desc.active i {
  transform: rotate(180deg);
}

.vehicleinfo .media-body h3 {
  color: var(--headings, #343c4d);
  font-size: 16px;
  margin: 0;
  font-weight: 500;
  line-height: 20px;
}

.vehicleinfo .media-body p {
  color: var(--secondary-subtitles, #7c868e);
  font-size: 12px;
  margin: 0;
  font-weight: 500;
  line-height: 20px;
}

.vehicleinfo .color {
  display: flex;
  align-items: center;
}

.vehicleinfo .color .name {
  color: var(--secondary-subtitles, #7c868e);
  font-size: 10px;
  font-weight: 500;
  line-height: 18px;
}

.vehicleinfo .v-color {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 8px;
}

.titleContent .btn-edit {
  background: transparent;
  border: none;
  color: var(--primary);
}

.addressInfo {
  align-items: flex-start;
}

.addressInfo .icon {
  width: 14px;
  height: 19px;
  font-size: 24px;
  color: var(--primary);
  display: inline-flex;
}

.addressInfo .media-body {
  flex: 1;
  padding: 0 5px;
}

.addressInfo .media-body h4 {
  margin: 0;
  color: var(--headings, #343c4d);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.addressInfo .media-body p {
  margin: 0;
  overflow: hidden;
  color: var(--secondary-text, #54595e);
  text-overflow: ellipsis;
  font-size: 12px;
  line-height: 20px;
}

.footerCollapseContent {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  padding: 8px;
}

.footerCollapseContent .media-body {
  color: var(--headings, #343c4d);
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  padding: 11px 12px;
}

.dateTime {
  display: inline-flex;
  gap: 8px;
}

.dateTime .date,
.dateTime .time {
  display: inline-flex;
  align-items: center;
  color: var(--primary, #0770d3);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.dateTime .date i,
.dateTime .time i {
  line-height: 0;
  margin-right: 4px;
}

.paymentCard {
  padding: 30px 40px;
  background: linear-gradient(180deg, #fefefe 0%, #d7d7d8 100%);
  height: 100%;
}

.opacityLow {
  opacity: 0.5;
}

.paymentMethod {
  gap: 17px;
  margin-bottom: 12px;
}

.pay {
  display: flex;
  border: 1px solid var(--secondary-light-color, #abb5be);
  padding: 12px;
  border-radius: 16px;
  background: var(--white, #fff);
  cursor: pointer;
}

.paymentMethod span {
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  border-radius: 16px;
  border: 1px solid var(--secondary-light-color, #abb5be);
  background: var(--default-white, #fff);
}

.paymentMethod .flex-1 {
  flex: 1;
  padding: 0 1rem;
}

.paymentMethod .flex-1 h4 {
  color: var(--headings, #343c4d);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 6px;
}

.paymentMethod .flex-1 p {
  color: var(--secondary-subtitles, #7c868e);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.paymentMethod input:checked + .pay {
  border: 1px solid var(--secondary, #033cad);
  background: var(--light-blue, #e6ecf7);
}

.paymentMethod input:checked + .pay span {
  background: var(--secondary, #fff);
  box-shadow: inset 0 0 0 4px #033cad;
  border: 1px solid var(--secondary, #033cad);
}

.brandCard {
  display: flex;
  align-items: center;
  gap: 5px;
}

.cardSummary {
  border-radius: 16px;
  background: var(--white, #fff);
  box-shadow: var(--shadow1);
  padding: 20px;
}

.cardSummary h4 {
  color: var(--headings, #343c4d);
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
}

.cardSummary .formGroup {
  display: flex;
  gap: 8px;
  margin-bottom: 1rem;
}

.cardSummary .formGroup .flex-1 {
  flex: 1;
}

.cardSummary .formGroup .btn {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
}

.cardSummary .formGroup .form-control {
  height: 38px;
  font-size: 16px;
  background: var(--card, #f4f9ff);
  border: none;
}

.cardSummary .formGroup .form-control::placeholder {
  color: rgba(7, 112, 211, 0.5);
}

.summaryTotal li,
.summaryDesc li {
  padding: 10px;
  display: flex;
  align-items: center;
}

.summaryTotal li .media-body,
.summaryDesc li .media-body {
  flex: 1;
  color: var(--secondary-subtitles, #7c868e);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.summaryTotal li .price,
.summaryDesc li .price {
  color: var(--headings, #343c4d);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.summaryTotal li .price del,
.summaryDesc li .price del {
  color: var(--primary);
}

.summaryTotal li {
  border-top: 1px solid var(--secondary-light);
}

.descardmodal.modal.show .modal-dialog {
  max-width: 400px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.cardAction {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  margin-top: 24px;
  gap: 10px;
}

.cardAction button.btnPrimary.btnIcon {
  min-width: 84.24px;
}

.cardAction button.btnSecondary.descard {
  min-width: 84.24px;
}

.cardAction .btn {
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
}

.cardAction .btn i {
  margin-left: 10px;
}

.collapseContent {
  transition: height 0.3s;
}

.infoDesc.hide,
.collapseContent.hide {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.infoDesc.show,
.collapseContent.show {
  opacity: 1;
  height: auto;
}

.orderContent.active .orderContentBox {
  background: var(--card, #f4f9ff);
}

.orderDetailModal {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 410px;
}

.orderDetailModal::-webkit-scrollbar {
  display: none;
}

.orderContent {
  margin-bottom: 16px;
}

.selected-item {
  border: 1px solid #043cad;
  border-radius: 9px;
}

.infoDesc {
  width: 100%;
  padding: 8px 12px;
  border-radius: 12px 12px 0 0;
  background: var(--card, #f4f9ff);
}

.infoDesc h5 {
  color: var(--headings, #343c4d);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  padding: 0 15px;
}

.infoDesc p {
  color: var(--secondary-subtitles, #7c868e);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.infoDesc .imgHolder {
  padding-left: 1rem;
  border-radius: 12px;
}

.infoDesc .imgHolder img {
  border-radius: 12px;
  max-height: 400px;
}

.wrapperThanks {
  position: relative;
  padding: 0;
}

.wrapperThanks .row {
  position: relative;
  z-index: 5;
  flex: 1;
  width: 100%;
}

.trackOrder {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.trackOrder ul {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
}

.trackOrder ul li img {
  height: 68px;
}

.trackOrder p {
  margin: 10px 0 0;
  font-size: 18px;
}

.trackOrder h4 {
  font-size: 22px;
  margin-bottom: 18px;
  font-weight: 500;
}

.wrapperThanks {
  min-height: calc(100vh - 129px);
  display: flex;
  flex-direction: column;
}

.trackOrder .btnSecondary {
  max-width: 379px;
  width: 100%;
  font-size: 16px;
  height: 38px;
  font-weight: 500;
}

.trackOrder .btnSecondary i {
  margin-left: 10px;
}

.orderId {
  height: 100%;
  background: linear-gradient(90deg, rgba(4, 172, 218, 0.88) 0%, #010f62 100%);
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #fff;
}

.orderId p,
.orderId h3 {
  margin: 0;
}

.orderId h3 {
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
}

.orderId p {
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.orderId .id {
  margin-top: 12px;
  color: var(--card);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.titlePage h3 {
  color: var(--white);
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  text-transform: uppercase;
}

.titlePage .btn {
  text-transform: uppercase;
  font-size: 14px;
  height: 40px;
  max-width: 219px;
  width: 100%;
}

.titlePage .btn i {
  margin-left: 10px;
}

.searchHistory {
  margin-top: 40px;
}

button.delte-servics {
  background: #efd2d5;
  padding: 16px 20px;
  color: var(--status-color-danger, #b22b38);
  font-size: 12px;
  font-weight: 700;
  border-radius: 30px;
  height: 40px;
}

button.delte-servics.active {
  background: #efd2d5 !important;
  color: var(--status-color-danger, #b22b38) !important;
  gap: 4px;
}

button.delte-servics:focus-visible {
  box-shadow: none !important;
}

.searchHistory .form-control {
  border-radius: 6px;
  background: var(--default-white, #fff);
  font-size: 16px;
  max-width: 240px;
}

.pagination {
  gap: 6px;
}

.page-item .page-link {
  line-height: 1;
  color: var(--primary, #0770d3);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 0;
  min-width: 38px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 14px;
}

.page-item:not(:first-child) .page-link {
  width: 38px;
  height: 30px;
}

.page-item:not(:first-child) .page-link,
.page-item:last-child .page-link,
.page-item:first-child .page-link {
  border-radius: 4px;
  height: 30px;
  min-width: auto;
}

.active > .page-link,
.page-link.active {
  background: var(--primary);
  color: var(--white);
  border: 1px solid var(--primary);
}

.tableWrapper {
  margin-top: 8px;
  border-radius: 8px;
  background: var(--card, #f4f9ff);
  overflow: hidden;
}

.customCheck .checkbox + label {
  margin: 0 !important;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
}

.customCheck .checkbox + label span {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border: 1px solid var(--light-trunks);
  border-radius: 4px;
}

.tableWrapper .table {
  margin: 0;
}

.tableWrapper .table tr.active {
  --bs-table-active-color: var(--bs-body-color) !important;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1) !important;
}

.tableWrapper .table tr th {
  background: var(--card, #f4f9ff);
  color: var(--headings, #343c4d);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  height: 56px;
  padding: 0.75rem 1rem;
  vertical-align: middle;
}

.tableWrapper .table tr td {
  padding: 0.75rem 1rem;
  vertical-align: middle;
}

.tableWrapper .customCheck {
  min-height: 24px;
  padding: 0;
  margin: 0;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.tableWrapper .form-check-label {
  line-height: 24px;
  cursor: pointer;
}

.customCheck .checkbox:checked + label span {
  background-repeat: no-repeat;
  background-position: center;
  border-color: var(--primary);
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="24" height="24" viewBox="0 0 24 24"%3E%3Cpath fill="none" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m5 14l4 4L19 8"%2F%3E%3C%2Fsvg%3E');
  background-color: var(--primary);
  background-size: 16px;
}

.tableWrapper .table tr td .service p,
.tableWrapper .table tr td .service h4 {
  color: var(--headings, #343c4d);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
}

.tableWrapper .table tr td .location {
  max-width: 196px;
  width: 100%;
  color: var(--secondary-text, #54595e);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.tableWrapper .table tr td .price {
  color: var(--headings, #343c4d);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.tableWrapper .table tr td .date,
.tableWrapper .table tr td .time {
  color: var(--headings, #343c4d);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.tableWrapper .table tr td .time {
  font-weight: 400;
}

.tableWrapper .badge {
  border-radius: 20px;
  padding: 4px 8px;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.btn-ellipsesCta {
  padding: 0;
  outline: none;
  border: none !important;
  font-size: 24px;
}

.btn-ellipsesCta i {
  font-size: 24px;
}

.btn-ellipsesCta:after {
  content: none;
}

.btn-ellipsesCta + .dropdown-menu {
  min-width: 137px;
  width: 137px;
  border-radius: 12px;
  background: var(--light-gohan, #fff);
  box-shadow: var(--shadow1);
  margin: 0;
  padding: 8px 4px 4px 4px;
  right: -4px !important;
}

.btn-ellipsesCta + .dropdown-menu .dropdown-item {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  padding: 4px;
}

.tableWrapper .table-hover > tbody > tr.active > *,
.tableWrapper .table-hover > tbody > tr:hover > * {
  background: #d5dae5;
}

.bg-primaryLight {
  background: #c5e3ff;
  color: var(--primary);
}

.bg-dangerLight {
  background: #ffd8dc;
  color: var(--status-color-danger, #b22b38);
}

.bg-successLight {
  background: #d2f8da;
  color: var(--status-color-danger, #1f8035);
}

.profileMain .title {
  text-align: center;
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 40px;
}

.cardProfile {
  max-width: 1163px;
  margin: 0 auto;
  border-radius: 9.097px;
  background: #fff;
  box-shadow: 0px 9.096638679504395px 18.19327735900879px 0px
      rgba(194, 194, 194, 0.16),
    0px 0px 27.289915084838867px 0px rgba(170, 170, 170, 0.16);
  padding: 25px;
  min-height: 595px;
}

.uploadFile + label {
  border-radius: 80px;
  background: var(--card, #f4f9ff);
  width: 118.256px;
  height: 120.076px;
  flex-shrink: 0;
  margin: 0;
}

.cardProfile .uploadFile + label {
  margin: 0;
}

.uploadFile + label .placeholderImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 1px solid var(--primary);
  border-style: dashed;
  cursor: pointer;
}

.uploadFile + label .placeholderImg .imgFluid {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.uploadFile + label .placeholderImg span {
  display: inline-block;
  color: var(--primary, #0770d3);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  opacity: 0.5;
  margin-top: 6px;
}

.cardProfile hr {
  border-color: #8cb7d5;
  height: 2px;
}

.cardProfile label {
  color: #4c535f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 6.5px;
}

.cardProfile .form-control {
  border: none;
  height: 38px;
}

.cardProfile .form-control:focus {
  border: 1px solid;
}

.cardProfile .form-group {
  margin-bottom: 13px;
}

.formButton {
  display: flex;
  gap: 10px;
  margin-top: 13px;
}

.formButton .btnIcon {
  text-transform: uppercase;
  padding: 16px 20px;
}

.formButton .btn {
  height: 40px;
}

.formButton .btnIcon i {
  margin-left: 10px;
}

.formButton .btn-reset {
  background: transparent;
  border: none;
  color: #4c535f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.imgHolder img.carpool-img {
  max-width: 380px;
  max-height: 400px;
}

.imgHolder img.arrowImg {
  max-width: 200px;
}

.sliderWrapper .mySwiper {
  height: 452px;
}

.sliderHolderHori .mySwiper {
  width: 100%;
}

.arrowImg {
  margin-left: 69px;
  margin-top: 85px;
}

.heroText h3 {
  font-size: 30px;
}

.tabContentBody {
  height: 328px;
}

.formWrapper {
  height: 319px;
}

.formWrapper.savedLocations {
  height: 468px;
}

.formWrapper.withslider {
  height: 319px;
}

.search-form {
  position: relative;
}

.search-container {
  position: relative;
  z-index: 12;
}

.search-form .search-results {
  position: absolute;
  z-index: 9;
  background: #fff;
  left: 5px;
  right: 0px;
  top: 100%;
  min-width: calc(100% - 10px);
  max-width: calc(100% - 10px);
  border-radius: 12px;
  background: var(--light-gohan, #fff);
  border: none;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4),
    0px 8px 24px 6px rgba(0, 0, 0, 0.16);
  padding: 4px 8px;
  max-height: 300px;
  overflow-y: auto;
}

.search-form .search-results li {
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 12px;
  cursor: pointer;
}

.search-form .search-results li:hover {
  background: var(--light-blue);
  color: var(--text-primary);
}

.customModal.profileModal .modal-body {
  padding: 32px;
}

.customModal.profileModal .modal-dialog {
  max-width: 768px;
}

.customModal.profileModal .modal-dialog h3 {
  color: #fff;
  font-size: 24px;
}

.customModal.profileModal label {
  color: #fff;
}

.customModal.profileModal .formButton {
  justify-content: end;
}

.customModal.profileModal .formButton .btn {
  max-width: 190px;
  width: 100%;
  position: relative;
  padding-right: 40px;
  font-size: 14px;
}

.customModal.profileModal .formButton .btn i {
  position: absolute;
  right: 20px;
  font-size: 16px;
}

.customModal.profileModal .cardProfile {
  padding: 0;
}

.cardSaved .dropdown-menu {
  left: auto !important;
  min-width: 118px;
  width: 118px;
}

.sticky-whatsaap {
  position: fixed;
  bottom: 60px;
  right: 55px;
  width: 64px;
  height: 64px;
  z-index: 100;
  cursor: pointer;
}

.cstTableHistory {
  overflow-y: auto;
  height: calc(100vh - 345px);
}

.cstTableHistory .table thead {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.loaderCst {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noHistoryImg {
  padding: 50px 0;
}

.noHistoryImg img {
  max-width: 400px;
}

.noCart img {
  max-width: 200px;
}

.timeDate {
  display: flex;
}

.timeList {
  min-width: 150px;
  width: 100%;
  border: 1px solid var(--beerus);
  border-radius: 0 16px 16px 0;
  margin-left: -1px;
}

.timeList h4 {
  font-weight: 600;
  font-size: 14px;
  padding: 8px;
  text-align: center;
  margin: 0;
}

.timeList ul li {
  display: flex;
  align-items: center;
  padding: 7.5px 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #54595e;
  cursor: pointer;
}

.timeList ul li span {
  flex: 1;
}

.timeList ul li i {
  opacity: 0;
  visibility: hidden;
}

.timeList ul li.selected {
  background: #e6ecf7;
  color: var(--primary);
}

.timeList ul li.selected i {
  opacity: 1;
  visibility: visible;
}

.canvasRight {
  left: auto !important;
  right: 0;
  transform: translateX(100%) !important;
  max-width: 514px;
  width: 100% !important;
}

.canvasRight.show {
  transform: translateX(0%) !important;
}

.selectedService span {
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.selectedService {
  padding: 6px 30px;
  border-radius: 12px 12px 0px 0px;
  background: var(--card, #f4f9ff);
}

.selectedService .serviceTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.selectedService .serviceTitle h4 {
  flex: 1;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  text-transform: uppercase;
  margin: 0;
}

.selectedService .serviceTitle h4 i {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.selectedService .serviceTitle h4 i img {
  max-width: 100%;
}

.selectedService .serviceTitle .price {
  background: var(
    --primary-gradient,
    linear-gradient(90deg, #0770d3 0%, #04acda 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
}

.formWrapper .error .roundedLabel + label,
.formWrapper .error .form-control,
.formWrapper .error .dropdown button.dropdown-toggle {
  border: 1px solid #dc3545;
}

.colorPickerRow.row label {
  line-height: 1;
}

.timeDateWrapper label span,
.colorPickerRow label span {
  display: none;
  font-size: 12px;
  margin: 2px 0 0;
}

.timeDateWrapper.error label span,
.colorPickerRow.error label span {
  color: #dc3545;
}

.timeDateWrapper.error label span,
.colorPickerRow.error label span {
  display: block;
}

/* Card History */
.cardHistoryRecord {
  border-radius: 8px;
  background: #fff;
  padding: 10px 16px;
  margin-bottom: 10px;
}

.cardHistoryRecord .record,
.cardHistoryRecord .headerHistory {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 8px;
}

.cardHistoryRecord .record .bg-secondary {
  background-color: #c5e3ff !important;
  color: #0770d3;
}

.cardHistoryRecord .record .recordId {
  display: inline-flex;
  align-items: baseline;
  gap: 4px;
}

.cardHistoryRecord .record .recordId span {
  color: #7c868e;
}

.cardHistoryRecord .service {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 0;
  border-bottom: 1px solid #ebebeb;
  margin: 0 0 8px;
}

.cardHistoryRecord .service h4 {
  font-size: 1rem;
  margin: 0;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: #343c4d;
}

.cardHistoryRecord .service h4 span {
  color: #7c868e;
}

.cardHistoryRecord .cardFooterHistory {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.cardHistoryRecord .cardFooterHistory .locationHistory {
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1;
}

.cardHistoryRecord .cardFooterHistory .locationHistory span {
  line-height: 1;
  color: var(--primary);
}

.cardHistoryRecord .cardFooterHistory .locationHistory .location {
  color: #54595e;
}

.cardHistoryRecord .cardFooterHistory .date,
.cardHistoryRecord .cardFooterHistory .time {
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
}

.cardHistoryRecord.active {
  background: #e6ecf7;
}

.postLoginBG {
  background: transparent;
}

.topbar.topbar-pre .address i,
.topbar.topbar-pre .address span,
.topbar.topbar-pre ul li,
.topbar.topbar-pre ul li a {
  color: #000 !important;
}

.topbar.topbar-pre .address i {
  margin: 0 !important;
}

.policyCntainer {
  height: 70dvh;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: white;
  padding: 30px 40px;
  border-radius: 12px;
}

.policyCntainer::-webkit-scrollbar-thumb {
  display: none;
}

.policyCntainer::-webkit-scrollbar-track {
  display: none;
}

.policyCntainer h2 {
  font-size: 24px;
  color: #343c4d;
  margin-bottom: 30px;
}

.policyCntainer h3 {
  font-size: 20px;
  color: #343c4d;
  margin-bottom: 15px;
}

.policyCntainer p {
  font-size: 16px;
  color: #54595e;
  position: relative;
  padding-left: 20px;
  /* Add left padding for indentation */
  margin: 8px 0;
  font-weight: 500;
}

.policyCntainer p::before {
  content: "\2022";
  /* Unicode character for a bullet point */
  position: absolute;
  left: 0;
  color: #04acda;
  /* Custom color for the bullet point */
  font-weight: 900;
  /* Make the bullet point bold */
}

.policyCntainer .policyBox {
  padding: 0 40px;
}

.wrapperMain h2.mainHeading {
  font-size: 32px;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

/* -------------------About-Us------------------- */
.AboutUs {
  overflow-y: scroll;
  height: calc(100vh - 164px);
}

.AboutUs {
  scrollbar-width: none;
}

/* Hide scrollbar for Webkit browsers (such as Chrome and Safari) */
.AboutUs::-webkit-scrollbar {
  display: none;
}

.awardWinning {
  margin-bottom: 50px;
}

.AboutUs {
  overflow-y: scroll;
  height: calc(100vh - 164px);
}

.AboutUs {
  scrollbar-width: none;
}

/* Hide scrollbar for Webkit browsers (such as Chrome and Safari) */
.AboutUs::-webkit-scrollbar {
  display: none;
}

.awardWinning {
  margin-bottom: 50px;
}

.awardWinning h1.abtHeading {
  font-size: 52px;
  color: var(--green);
  font-weight: 600;
}

.awardWinning img.aboutBanner {
  max-width: 500px;
  border-radius: 100%;
  width: 100%;
}

h2.weAreHeading {
  font-size: 40px;
  color: var(--green);
  font-weight: 600;
  text-transform: uppercase;
}

OUR ACCOMPLISHMENTS .ourVision {
  margin-bottom: 50px;
}

.ourVision h2.visionHeading {
  font-size: 32px;
  color: var(--green);
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 12px;
}

.ourVision p.visionContent {
  font-size: 20px;
  color: white;
  margin-bottom: 12px;
}

.ourVision img.visionImg {
  width: 70px;
  height: 59px;
}

.contactUs {
  background: #09102e;
  color: #fff;
  padding: 110px 0;
}

button.ConactSubmit {
  font-size: 14px;
  text-transform: uppercase;
  color: var(--green);
  letter-spacing: normal;
  background: none;
}

button.ConactSubmit:hover {
  letter-spacing: 2px;
  background: none;
  color: var(--green);
}

.form-floating > label {
  color: #0689f0;
}

p.contactContent {
  font-size: 20px;
  color: white;
  margin-bottom: 12px;
  max-width: 350px;
}

ul.AccomplishmentUl {
  display: flex;
  align-items: center;
}

ul.AccomplishmentUl li {
  width: 185px;
}

h3.OurYears {
  font-size: 32px;
  color: white;
  font-weight: 600;
  margin-left: -20px;
}

h3.OurYears::after {
  content: "";
  position: absolute;
  top: 52%;
  left: 126px;
  width: 4.5em;
  height: 2px;
  background: linear-gradient(
    78.98deg,
    #0443ba 0.73%,
    #065ff1 53.02%,
    #03cbd1 106.43%
  );
  display: block;
}

h3.OurYears.one::after {
  left: 312px;
}

h3.OurYears.three::after {
  left: 681px;
}

h3.OurYears.two::after {
  left: 496px;
}

h3.OurYears.four::after {
  left: 866px;
}

h3.OurYears.five::after {
  display: none;
}

.AccomplishmentBox p.AccomplishmentText {
  font-size: 1rem;
  color: white;
  margin-bottom: 0;
}

.AccomplishmentBox {
  width: 11em;
  height: 9em;
  padding: 1.875em 1.1em;
  text-align: center;
  align-items: center;
  --borderWidth: 3px;
  background: #011771;
  box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3.375em;
  border: 3px solid #043cad;
  position: absolute;
  top: 14px;
  left: 27px;
  display: flex;
  justify-content: center;
}

.AccomplishmentBox.one {
  top: auto;
  bottom: -7px;
  left: 213px;
}

.AccomplishmentBox.three {
  top: auto;
  bottom: -22px;
  left: 568px;
  width: 12em;
  height: 10em;
  padding: 1.875em 1.1em;
}

.AccomplishmentBox.two {
  top: -4px;
  bottom: 0;
  left: 392px;
  width: 12em;
  height: 10em;
  padding: 1.875em 1.1em;
}

.AccomplishmentBox.four {
  top: -7px;
  bottom: 0;
  left: 749px;
  width: 12em;
  height: 10em;
  padding: 30px 10px;
}

.AccomplishmentBox.five {
  top: auto;
  bottom: -22px;
  left: 939px;
  width: 12em;
  height: 10em;
  padding: 1.875em 1.1em;
}

.AccomplishmentBox::after {
  content: "";
  position: absolute;
  left: 75px;
  bottom: -59%;
  width: 2px;
  height: 3.25em;
  display: block;
  background: linear-gradient(
    78.98deg,
    #0443ba 0.73%,
    #065ff1 53.02%,
    #03cbd1 106.43%
  );
  transform: translate(0, -50%);
  z-index: -1;
}

.AccomplishmentBox.one::after {
  left: 73px;
  bottom: 0;
  top: -30px;
}

.AccomplishmentBox.three::after {
  left: 89px;
  bottom: 0;
  top: -30px;
}

.AccomplishmentBox.five::after {
  left: 89px;
  bottom: 0;
  top: -30px;
}

.AccomplishmentBox.two::after {
  left: 80px;
  bottom: -82px;
  top: auto;
}

.AccomplishmentBox.four::after {
  left: 92px;
  bottom: -82px;
  top: auto;
}

.imgApp ul li h2.headingLink {
  font-size: 34px;
  color: white;
  font-weight: 700;
  max-width: 300px;
}

.imgApp ul li h2.headingLink p.downloadText {
  font-size: 28px;
  color: white !important;
  font-weight: 700;
  max-width: 300px;
}

.imgApp ul li h2.AndVisit {
  font-size: 34px;
  color: white;
  font-weight: 700;
  max-width: 300px;
}

.imgApp ul li.googleLinks {
  background-color: white;
  border-radius: 100px;
  padding: 10px;
  max-width: 210px;
  cursor: pointer;
}

.imgApp ul li.googleLinks:hover {
  background-color: #03cbd1;
}

/* Changes  */
.formRow {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.searchCard label {
  color: #000;
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

.cst-select .react-select__indicator-separator {
  display: none;
}

.cst-select .react-select__control {
  font-size: 11.25px;
  border-radius: 6px;
  min-height: 42px;
  border-color: #eaecf0;
  outline: none;
  box-shadow: none;
}

.cst-select .react-select__control:hover,
.cst-select .react-select__control:focus {
  outline: none;
  border: 1.125px solid #3254a5;
  box-shadow: 0px 0px 0px 2.25px rgba(76, 191, 208, 0.25);
}

.formRow .cst-select:first-of-type {
  flex: 0 0 130px;
  max-width: 130px;
}

.formRow .cst-select:nth-of-type(2) {
  flex: 0 0 185px;
}

.formRow .cst-select:nth-of-type(3) {
  flex: 1;
}

.formRow .cst-select:nth-of-type(4) {
  flex: 1;
}

.cst-select .react-select__value-container div {
  display: flex;
  gap: 5px;
}

.cst-select .react-select__value-container img {
  width: 14px !important;
  height: 14px !important;
  margin: 0 !important;
}

.cst-select .react-select__value-container span {
  line-clamp: 1;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.cst-select .react-select__indicator {
  padding: 0px;
  margin-right: 12px;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23292D32' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='m4 8.417l6.587 6.587a2.013 2.013 0 0 0 2.826 0L20 8.417'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
}

.iconArrow {
  padding: 0px;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23292D32' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='m4 8.417l6.587 6.587a2.013 2.013 0 0 0 2.826 0L20 8.417'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 6px;
  flex: 0 0 15px;
}

.cst-select .react-select__indicator svg {
  display: none;
}

.cst-select .react-select__menu {
  min-width: 185px;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 2px 4px 0px rgba(24, 39, 75, 0.1);
  z-index: 999;
}

.cst-select .react-select__menu {
  min-width: 100%;
}

.cst-select .react-select__menu .react-select__menu-list div {
  font-size: 14px;
  cursor: pointer;
  padding: 6px 12px !important;
  color: #1f2336;
}

.cst-select .react-select__menu .react-select__menu-list hr {
  margin: 0;
  border-color: #e2e8f0;
  opacity: 1;
}

.cst-select .react-select__menu div[aria-selected="true"] {
  background: #fff;
  color: #3254a5;
}

.cst-select .react-select__menu .react-select__menu-list {
  padding: 0;
}

.cst-select #react-select-10-input {
  display: none;
}

.cst-select .react-select__menu div.history,
.cst-select .react-select__menu div:hover {
  color: #3254a5;
}

.cst-select .react-select__menu div img {
  width: 14px;
}

.searchCard .form-group {
  margin-bottom: 16px;
}

.groupSelection {
  display: flex;
}

.groupSelection .cst-select {
  position: relative;
}

.groupSelection .cst-select:first-of-type {
  flex: 1;
}

.groupSelection .cst-select:first-of-type::after {
  content: "";
  width: 0.75px;
  height: 18px;
  background: #eaecf0;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
}

.groupSelection .cst-select:nth-of-type(2) {
  flex: 0 0 112px;
  width: 112px;
}

.groupSelection {
  border-radius: 6px;
  border: 0.75px solid #eaecf0;
  background: #fff;
}

.groupSelection .cst-select .react-select__control:focus,
.groupSelection .cst-select .react-select__control {
  border: none;
  outline: none;
  box-shadow: none;
}

.searchCard .icon {
  font-size: 15px;
  color: #667085;
}

.groupSelection .react-datepicker {
  width: 331px;
  border-radius: 16px;
}

.groupSelection .form-control {
  border-radius: 0;
  background: #fff;
  border: none;
  min-height: 42px;
  padding-left: 36px;
}

.groupSelection .relative {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
}

.groupSelection .relative:after {
  content: "";
  width: 0.75px;
  height: 18px;
  background: #eaecf0;
  display: inline-block;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
}

.groupSelection .relative img {
  left: 12px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.groupSelection .cst-select.time-field {
  position: relative;
  flex: 1;
}

.groupSelection .time-field .react-select__control {
  padding-left: 36px;
}

.groupSelection .time-field img {
  left: 12px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.homeMain .flex__1 .imgHolder {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.searchCard .locationSearch {
  position: relative;
}

.searchCard .locationSearch .react-select__indicators {
  display: none;
}

.searchCard .locationSearch .react-select__control {
  padding-left: 36px;
}

.searchCard .row {
  flex: 1;
}

.searchCard .locationSearch img {
  left: 12px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.searchCard .react-select__single-value {
  margin: 0;
}

.searchCard .location-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.searchCard .location-header .flex-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.searchCard .location-header .flex-right button {
  border: none;
  background: transparent;
  line-height: 1;
}

.searchCard .location-header label {
  margin: 0;
}

.switch .switch-field + label {
  width: 24px;
  height: 14px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 0;
  background: #c8d1ff;
  cursor: pointer;
}

.switch .switch-field:checked + label {
  background: linear-gradient(
    90deg,
    rgba(50, 84, 165, 1) 0%,
    rgba(76, 192, 208, 1) 100%
  );
}

.switch .switch-field + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 1px;
  margin-left: 0;
  transition: all 0.3s;
}

.switch .switch-field:checked + label:after {
  margin-left: 10px;
}

.flex_1 {
  flex: 1;
}

.g-2 {
  margin: 0 -6px;
  margin-top: 0 !important;
}

.g-2 [class*="col-"] {
  padding: 0 6px;
  margin: 0;
}

.searchCard {
  margin-bottom: 30px;
}

.searchCard::-webkit-scrollbar {
  display: none;
}

.searchCard {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.searchCard .btn-ctaBtn {
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(50, 84, 165, 1) 0%,
    rgba(76, 192, 208, 1) 100%
  );
  color: #fff;
  border-radius: 6px;
  height: 42px;
}

.searchCard .btn-ctaBtn:hover {
  background: linear-gradient(
    90deg,
    rgba(76, 192, 208, 1) 100% rgba(50, 84, 165, 1) 0%
  );
}

.navMain {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
}

.homeMain .imgHolder img {
  max-width: 100%;
}

.selectIcon .btn-primary {
  background: transparent;
  border-radius: 6px;
  border: 0.75px solid #eaecf0;
  background: #fff;
  padding: 0 12px;
  color: #667085;
  font-family: "DM Sans";
  font-size: 11.25px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  min-height: 42px;
  width: 100%;
  justify-content: flex-start;
  padding-right: 20px;
}

.groupSelection.servicesMenu .cst-select:nth-of-type(2) .btn-primary {
  line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.selectIcon.show .form-control {
  background: transparent;
  border-radius: 0;
  border: none;
  padding: 0;
}

.selectIcon .btn-primary:focus {
  box-shadow: none;
  background: transparent;
  color: #667085;
}

.selectIcon .btn-check:checked + .btn,
.selectIc on .btn.active,
.selectIcon .btn.show,
.selectIcon .btn:first-child:active,
.selectIcon:not(.btn-check) + .btn:active {
  background: transparent;
  color: #667085;
}

.selectIcon .dropdown-toggle::after {
  content: "";
  border: none;
  margin-left: auto;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23292D32' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='m4 8.417l6.587 6.587a2.013 2.013 0 0 0 2.826 0L20 8.417'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}

.selectIcon .dropdown-item {
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.14px;
  padding: 6px 12px;
  color: #1f2336;
}

.selectIcon .dropdown-item:hover {
  background-color: #deebff;
}

.selectIcon .dropdown-menu hr {
  margin: 0;
}

.selectIcon .dropdown-menu {
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
  border: 1px solid #e2e8f0;
  box-shadow: 0px 2px 4px 0px rgba(24, 39, 75, 0.1);
}

.groupSelection .selectIcon .btn-primary {
  border: none;
}

.selectIcon .dropdown-toggle span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.groupSelection .react-datepicker-wrapper .form-control {
  padding-right: 0;
}

input.streetNumberInput.form-control {
  background: none;
  border-radius: 6px;
  padding: 10px 12px;
}

.popoverWash p,
.popoverWash h4 {
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.14px;
  margin: 0;
}

.popoverWash p {
  margin-bottom: 22px;
}

.popoverWash h4 {
  font-weight: 700;
}

.popoverWash ul {
  padding-left: 30px;
}

.popoverWash ul li {
  list-style: disc;
}

.secondaryHeader {
  padding: 0 20px;
  position: relative;
  margin-top: 20px;
}

.secondaryHeader .card-hero {
  border-radius: 23.897px;
  background-image: url(./assets/images/bgAbout.png);
  background-size: cover;
  background-position: bottom right;
  color: #fff;
  padding: 144px 100px;
  overflow: hidden;
}

.secondaryHeader .card-hero .title {
  max-width: 609px;
}

.secondaryHeader .card-hero .title span {
  display: block;
  color: #4cc0d0;
  font-family: "DM Sans";
  font-size: 38.832px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 40px;
}

.secondaryHeader .card-hero .title h2 {
  color: #fff;
  font-family: "DM Sans";
  font-size: 63.243px;
  font-style: normal;
  font-weight: 700;
  line-height: 104%;
  margin-bottom: 40px;
}

.secondaryHeader .card-hero .title a {
  border-radius: 46px;
  background: linear-gradient(90deg, #3254a5 0%, #4cc0d0 100%);
  box-shadow: 8px 9px 21.6px 0px rgba(51, 89, 167, 0.54);
  color: #fff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 273px;
}

.secondaryHeader .headerWrap {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.secondaryHeader .headerWrap .header {
  position: static;
  background: transparent;
}

.secondaryHeader .naveBarMain a.withOutLgn {
  color: #fff;
}

.secondaryHeader .navbarMenu .navbar-nav a {
  color: #fff;
}

.secondaryHeader .cartLink {
  color: #fff;
}

.secondaryHeader .toggle-button,
.secondaryHeader .btn-user .name {
  color: #fff;
}

.secondaryHeader .toggle-button img {
  filter: brightness(0) invert(1);
}

.secondaryHeader .naveBarMain {
  padding: 36px 0;
  height: auto;
}

.whoWeAre {
  padding: 148px 0;
}

.whoWeAre .content h2 {
  color: #09102e;
  font-family: "DM Sans";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 93.333%;
  margin-bottom: 32px;
}

.whoWeAre .content p {
  color: #2b2b2b;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 32px;
}

.conterCard {
  text-align: center;
}

.conterCard h4 {
  text-align: center;
  font-family: "DM Sans";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 88.667%;
  text-transform: uppercase;
  background: linear-gradient(90deg, #3254a5 0%, #4cc0d0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.conterCard span {
  display: block;
  margin: 0;
  color: #050b20;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 175.781%;
  text-transform: uppercase;
}

.whoWeAre .img-holder {
  border-radius: 12px;
}

.whoWeAre .img-holder img {
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.cta-primary {
  border-radius: 46px;
  background: linear-gradient(90deg, #3254a5 0%, #4cc0d0 100%);
  padding: 19px 46px;
  color: #fff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.cta-primary:hover {
  background: linear-gradient(90deg, #4cc0d0 0%, #3254a5 100%);
}

.ourEstablishment {
  padding: 155px 30px;
  margin-top: 48px;
  border-top: 1px solid #e1e1e1;
  position: relative;
}

.ourEstablishment .logo {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
}

.ourEstablishment .container {
  position: relative;
  z-index: 10;
}

.ourEstablishment h2 {
  text-align: center;
  color: #09102e;
  text-align: center;
  font-family: "DM Sans";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 93.333%;
  text-transform: uppercase;
  margin-bottom: 118px;
}

.ourEstablishment ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 70px;
  position: relative;
  z-index: 5;
}

.ourEstablishment ul:after {
  content: "";
  content: "";
  width: 100%;
  height: 1px;
  border-top: 1px dashed #b1aca9;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  max-width: 1157px;
  margin: 0 auto;
}

.ourEstablishment ul li {
  display: flex;
  text-align: right;
  gap: 20px;
}

.ourEstablishment ul li .icon {
  background: #fff;
  filter: drop-shadow(4px 4px 15px rgba(157, 157, 157, 0.43));
  width: 72px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  position: relative;
  z-index: 5;
}

.ourEstablishment ul li .icon img {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.ourEstablishment ul li .icon:after {
  content: "";
  width: 23px;
  height: 23px;
  background-image: url(./assets/images/ellipse.svg);
  left: 0;
  position: absolute;
  bottom: -60px;
  background-size: cover;
}

.ourEstablishment ul li:first-of-type .icon:after {
  right: 0;
  left: auto;
}

.ourEstablishment ul li:nth-of-type(7) .icon:after,
.ourEstablishment ul li:nth-of-type(6) .icon:after,
.ourEstablishment ul li:nth-of-type(5) .icon:after {
  bottom: auto;
  left: auto;
  right: 0;
  top: -48px;
}

.ourEstablishment ul li:nth-of-type(5) .icon:after {
  background-image: url(./assets/images/ellipse2.svg);
}

.ourEstablishment ul li .media-body {
  flex: 1;
}

.ourEstablishment ul li .media-body p {
  max-width: 207px;
  color: #2b2b2b;
  font-family: "DM Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

.ourEstablishment ul li .media-body h4 {
  color: var(--Heading, #09102e);
  font-family: "DM Sans";
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 39.9px;
  text-transform: uppercase;
}

.ourEstablishment ul li:first-of-type {
  text-align: left;
}

.ourEstablishment ul li:nth-of-type(7),
.ourEstablishment ul li:nth-of-type(6),
.ourEstablishment ul li:nth-of-type(5) {
  text-align: left;
}

.ourEstablishment ul li:nth-of-type(5) .icon {
  background: linear-gradient(90deg, #3254a5 0%, #4cc0d0 100%);
  filter: drop-shadow(4px 4px 15px #4cc0d0);
}

.ourEstablishment ul li:nth-of-type(5) .media-body h4 {
  background: linear-gradient(90deg, #3254a5 0%, #4cc0d0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.whyChoose {
  background: #f9fbfc;
  padding: 100px 0;
}

.whyChoose .title {
  color: #09102e;
  font-family: "DM Sans";
  font-size: 36.456px;
  font-style: normal;
  font-weight: 700;
  line-height: 43.747px;
  text-transform: capitalize;
  max-width: 335px;
}

.card__whyChoose {
  margin-bottom: 24px;
}

.card__whyChoose h3 {
  color: #09102e;
  font-family: "DM Sans";
  font-size: 18.228px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.card__whyChoose p {
  color: #2b2b2b;
  font-family: "DM Sans";
  font-size: 13.671px;
  font-style: normal;
  font-weight: 400;
  line-height: 185%;
  margin-bottom: 16px;
}

.card__whyChoose ul li {
  color: #2b2b2b;
  font-family: "DM Sans";
  font-size: 13.671px;
  font-style: normal;
  font-weight: 400;
  line-height: 185%;
}

.card__whyChoose .icon {
  margin-bottom: 20px;
  height: 54px;
  width: 54px;
}

.card__whyChoose .icon img {
  max-width: 100%;
}

.contactUs h2 {
  color: #4cc0d0;
  font-family: "DM Sans";
  font-size: 55px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  margin-bottom: 45px;
}

.contactUs .form-control {
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  background: transparent;
  color: #fff;
  min-height: 60px;
  font-size: 1rem;
}

.contactUs .form-control::placeholder {
  color: rgba(255, 255, 255, 0.3);
}

.contactUs form label {
  color: #fff;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.contactUs form .btn.cta {
  border-radius: 48px;
  background: var(--Primary, linear-gradient(90deg, #3254a5 0%, #4cc0d0 100%));
  color: #fff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 19px 70px;
}

.contactUs h4 {
  color: #fff;
  font-family: "DM Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 20px;
}

.contactUs p {
  color: #fff;
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  gap: 18px;
  margin-top: 12px;
}

.contactUs p span {
  flex: 1;
}

.contactUs a {
  display: flex;
  gap: 18px;
  margin-top: 12px;
  color: #fff;
}

.contactWrapper {
  padding: 36px 0;
  padding-left: 100px;
  border-left: 1px solid #cfd3dc;
  margin-left: 70px;
}

.contactWrapper hr {
  border-color: #cfd3dc;
  opacity: 1;
  margin: 50px 0;
}

.footer {
  padding: 70px 0;
}

footer .footer-content p {
  color: var(--Paragraph, #2b2b2b);
  font-family: "Poppins", sans-serif;
  font-size: 12.739px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.019px;
  max-width: 225px;
  margin: 20px 0;
}

.socialLInks {
  display: flex;
  gap: 10px;
}

.socialLInks li a {
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border: 1px solid #d4d4d8;
  border-radius: 50%;
  color: #090914;
}

.socialLInks li a:hover {
  background: linear-gradient(90deg, #3254a5 0%, #4cc0d0 100%);
  color: #fff;
}

.quick-links h4 {
  color: #09102e;
  font-family: "Poppins", sans-serif;
  font-size: 11.829px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.379px;
  letter-spacing: 0.91px;
  text-transform: uppercase;
  margin-bottom: 26px;
}

.quick-links ul li a {
  color: #2b2b2b;
  font-family: "Poppins", sans-serif;
  font-size: 12.739px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.019px;
}

.quick-links ul li a:hover {
  color: #0689f0;
}

.quick-links p {
  color: #2b2b2b;
  font-family: "Poppins", sans-serif;
  font-size: 12.739px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.019px;
}

footer .copyrights p {
  color: #52525b;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 12.739px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.019px;
  margin: 0;
  padding: 39px 0;
  border-top: 1px solid #e8e8e8;
}

.error {
  border: 1px solid red;
  border-radius: 6px;
}

.cst-select.colorSelect .react-select__menu {
  min-width: 100%;
  width: 100%;
}

.carType.cst-select .dropdown-item {
  color: #3254a5;
}

.selectIcon .dropdown-item.selected,
.carType.cst-select .dropdown-item.selected {
  background-color: #deebff;
}

.cst-select .react-select__menu div[aria-selected="true"] {
  background-color: #deebff;
}

.carType.cst-select hr {
  opacity: 1;
  border-color: #e2e8f0;
}

.carType.cst-select hr ~ .dropdown-item {
  color: #1f2336;
}

.titlePopup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.titlePopup .price {
  font-size: 18px;
}

.popoverWash {
  background-color: #deebff;
}

.tooltipBx p {
  margin: 0;
}

body {
  overflow-y: auto;
  min-height: 100vh;
}

.heroProfile {
  background-image: url(./assets/images/profileHero.png);
  background-size: cover;
  background-position: top center;
  padding: 100px 0;
}

.heroProfile .topVector {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.heroProfile .topVector .icon {
  height: 30px;
}

.heroProfile .topVector .logoIcon {
  height: 60px;
}

.heroMain .logo {
  text-align: center;
  padding-top: 100px;
}

.heroMain .logo img {
  height: 150px;
  max-width: 100%;
}

.profileWhoWe {
  padding: 100px 0;
}

.profileWhoWe .content {
  border-radius: 12px;
  max-width: 473px;
  margin: 0 auto;
  background: var(--primary-gradiant);
  padding: 2px;
}

.profileWhoWe .content h2 {
  color: #011771;
  font-family: "DM Sans";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 93.333%;
  margin-bottom: 32px;
}

.profileWhoWe .content p {
  color: #011771;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 32px;
}

.profileWhoWe .content .bg-white {
  border-radius: 12px;
  padding: 20px;
}

.number .titleBar,
.whyJoinCompany .titleBar,
.profileWhoWe .titleBar {
  display: flex;
  align-items: center;
  gap: 60px;
  margin-bottom: 20px;
  position: relative;
}

.number .titleBar span,
.whyJoinCompany .titleBar span,
.profileWhoWe .titleBar span {
  flex: 1;
  height: 1px;
  background: var(--primary-gradiant);
}

.number .titleBar,
.whyJoinCompany .titleBar,
.profileWhoWe .titleBar {
  position: relative;
}

.profileWhoWe .titleBar .star1 {
  position: absolute;
  right: 0;
  bottom: -80px;
  width: 60px;
}

.profileWhoWe .img-holder {
  position: relative;
}

.profileWhoWe .img-holder .star2 {
  position: absolute;
  right: 0;
  top: 150px;
  width: 60px;
}

.visionMission {
  padding: 100px 0;
}

.number .titleBar,
.value .titleBar,
.visionMission .titleBar {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.number .titleBar .flex1,
.value .titleBar .flex1,
.visionMission .titleBar .flex1 {
  flex: 1;
  text-align: right;
}

.number .titleBar img,
.value .titleBar .flex1 img,
.visionMission .titleBar .flex1 img {
  height: 69px;
}

.value .titleBar span,
.visionMission .titleBar span {
  width: 150px;
  height: 8px;
  background: #85d0ff;
  position: relative;
}

.value .titleBar span:after,
.visionMission .titleBar span:after {
  content: "";
  width: 50%;
  height: 8px;
  background: #539be0;
  position: absolute;
  left: 0;
  top: 0;
}

.value h2,
.visionMission h2 {
  color: #011771;
  font-family: "DM Sans";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 93.333%;
  margin-bottom: 32px;
}

.card-vision {
  background: rgb(6, 99, 168);
  background: linear-gradient(
    360deg,
    rgba(6, 99, 168, 1) 0%,
    rgba(94, 221, 230, 1) 100%
  );
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 20px;
}

.card-vision .img-holder {
  height: 100%;
}

.card-vision .img-holder img {
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.card-vision .content {
  padding: 20px;
}

.card-vision .content h4 {
  font-size: 26px;
  color: #011771;
  font-family: "DM Sans";
  font-weight: 700;
}

.card-vision .content p {
  font-size: 22px;
  text-align: justify;
}

.cardBox .card-vision:nth-child(2) .content {
  padding: 20px;
}

.value .content {
  padding: 20px;
  background: rgb(6, 99, 168);
  background: linear-gradient(
    360deg,
    rgba(6, 99, 168, 1) 0%,
    rgba(94, 221, 230, 1) 100%
  );
  border-radius: 20px;
}

.value .content h4 {
  font-size: 22px;
  font-weight: 700;
  font-family: "DM Sans";
  color: #011771;
}

.value .content ul li {
  display: flex;
  font-family: "DM Sans";
  gap: 1rem;
}

.value .content ul {
  counter-reset: section;
}

.value .content ul li::before {
  counter-increment: section;
  content: counter(section);
  font-size: 18px;
  color: #011771;
  font-weight: 700;
  font-family: "DM Sans";
}

.value .content ul li span {
  font-size: 18px;
  color: #011771;
}

.value .img-holder {
  border-radius: 20px;
  height: 100%;
}

.value .img-holder img {
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
}

.value {
  padding-bottom: 100px;
}

.whyJoinCompany {
  padding-bottom: 100px;
}

.whyJoinCompany h2 {
  color: #09102e;
  font-family: "DM Sans";
  font-size: 36.456px;
  font-style: normal;
  font-weight: 700;
  line-height: 43.747px;
  text-transform: capitalize;
  text-align: center;
  margin: 30px 0;
}

.whyJoinCompany .content {
  background-image: url(./assets/images/bg-why-jino.png);
  background-size: cover;
  background-position: top center;
  position: relative;
  border-radius: 30px;
  padding: 30px 15px 0;
}

.whyJoinCompany .content:after {
  content: "";
  position: absolute;
  border-radius: 30px;
  inset: 0;
  background: rgb(6, 99, 168);
  background: linear-gradient(
    360deg,
    rgba(6, 99, 168, 0.7) 0%,
    rgba(94, 221, 230, 0.8) 100%
  );
}

.whyJoinCompany .item {
  text-align: center;
  position: relative;
  z-index: 5;
  margin-bottom: 30px;
}

.whyJoinCompany .item h3 {
  font-size: 18px;
  color: #09102e;
  font-family: "DM Sans";
  font-weight: 700;
  max-width: 250px;
  margin: 0 auto;
}

.whyJoinCompany .item .icon img {
  max-height: 60px;
  object-fit: contain;
  margin-bottom: 20px;
}

.services-profile {
  padding: 100px 0;
}

.services-profile img {
  max-width: 100%;
}

.services-profile h2 {
  color: #011771;
  font-family: "DM Sans";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 93.333%;
  position: relative;
  margin-bottom: 32px;
  padding-top: 20px;
}

.services-profile h2:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  height: 5px;
  background: var(--primary-gradiant);
}

.number .titleBar {
  display: flex;
  align-items: center;
}

.number .img-holder {
  position: relative;
}

.number .img-holder img {
  max-width: 100%;
}

.number .img-holder span {
  position: absolute;
  left: 0;
  right: 0;
  top: 130px;
  z-index: 5;
  color: #fff;
  font-size: 32px;
  letter-spacing: 7px;
  font-weight: bold;
  font-family: "DM Sans";
  text-align: center;
}

.number h3 {
  color: #011771;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
}

.number p {
  font-size: 20px;
  color: #011771;
  font-family: "DM Sans";
}

.number {
  padding-bottom: 100px;
}

.regionalMain {
  padding: 100px 0 0;
  background-size: cover;
  background-image: url(./assets/images/bg-regional.png);
  background-position: bottom center;
}

.regionalMain h2 {
  font-size: 66px;
  color: #011771;
  font-family: "DM Sans";
  background: #fff;
  padding: 10px 60px;
  display: block;
  text-align: center;
  border-radius: 0 24px 24px 0;
}

.regionalMain .img-holder {
  padding-left: 100px;
}

.cardBenefits {
  text-align: center;
}

.partnership {
  padding: 100px 0;
}

.cardBenefits .icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin: 0 auto 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-gradiant);
  color: #fff;
  border: 3px solid #010f62;
}

.cardBenefits h4 {
  font-size: 24px;
  font-weight: 700;
  color: #011771;
  font-family: "DM Sans";
}

.cardBenefits p {
  color: #011771;
  font-family: "DM Sans";
  font-size: 20px;
}

.partnership h2 {
  background: rgb(90, 218, 228);
  background: radial-gradient(
    circle,
    rgba(90, 218, 228, 1) 2%,
    rgba(1, 77, 174, 1) 100%
  );
  font-size: 66px;
  color: #fff;
  font-family: "DM Sans";
  padding: 10px 60px;
  display: block;
  text-align: center;
  border-radius: 24px 0px 0px 24px;
  margin-left: 30px;
}

.partnership .container {
  margin-left: auto;
  margin-right: 0;
  max-width: calc(50vw + 663px) !important;
}

.our-partners {
  padding: 100px 0;
  background: rgb(90, 218, 228);
  background: radial-gradient(circle, #5adae4 2%, #014dae 100%);
  text-align: center;
}

.our-partners h2 {
  text-align: center;
  font-size: 66px;
  color: #fff;
  font-family: "DM Sans";
}

.our-partners ul {
  list-style: none;
  padding: 5px 20px;
  background: #fff;
  border-radius: 100px;
  border: 3px solid #010f62;
  margin-top: 50px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px -15px 0;
  flex-wrap: wrap;
  overflow: hidden;
}

.our-partners ul li {
  padding: 0 15px;
}

.our-partners ul li img {
  height: 100px;
  object-fit: contain;
}

.our-partners ul:first-of-type {
  max-width: 1000px;
}

.our-partners ul li img {
  max-width: 100%;
  object-fit: cover;
}

.our-clients {
  padding: 100px 0;
  background: rgb(1, 77, 174);
  background: linear-gradient(
    360deg,
    rgba(1, 77, 174, 1) 2%,
    rgba(90, 218, 228, 1) 100%
  );
  text-align: center;
}

.our-clients h2 {
  text-align: center;
  font-size: 66px;
  color: #fff;
  font-family: "DM Sans";
  margin-bottom: 50px;
}

.our-clients ul {
  padding: 5px 60px;
  background: #fff;
  border-radius: 100px;
  border: 3px solid #010f62;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  overflow: hidden;
}

.our-clients ul:last-of-type {
  display: inline-flex;
}

.our-clients ul li img {
  height: 80px;
  object-fit: contain;
}

.our-clients ul li img {
  max-width: 100%;
}

.partnership-collaborate {
  padding: 100px 0;
}

.partnership-collaborate h2 {
  font-size: 66px;
  font-family: "DM Sans";
  margin-bottom: 20px;
  color: #011771;
}

.partnership-collaborate .content {
  position: relative;
  border-radius: 12px;
  background: var(--primary-gradiant);
  padding: 2px;
  max-width: 500px;
}

.partnership-collaborate .content .inner-content {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
}

.partnership-collaborate .content ul li {
  display: flex;
  gap: 8px;
  margin-bottom: 20px;
}

.partnership-collaborate .content ul li i img {
  height: 20px;
}

.partnership-collaborate .content ul li span {
  flex: 1;
}

.servicesMain {
  padding: 50px 0;
}

.servicesMain h2 {
  color: #09102e;
  font-family: "DM Sans";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: 93.333%;
  margin-bottom: 32px;
}

.servicesMain ul li {
  list-style: disc;
}

.servicesMain ul {
  padding-left: 30px;
}

.servicesMain ul li,
.servicesMain p {
  color: #2b2b2b;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 32px;
  margin: 0;
}

.servicesMain h4,
.servicesMain ul,
.servicesMain p {
  margin-bottom: 10px;
}

.servicesMain h3 {
  font-family: "DM Sans";
  font-weight: 700;
}

.servicesMain h2 {
  text-align: center;
  margin: 100px 0;
  padding-bottom: 20px;
  font-family: "DM Sans";
  font-weight: 700;
  background: linear-gradient(90deg, #3254a5 0%, #4cc0d0 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
}

.servicesMain h2:after {
  content: "";
  width: 160px;
  height: 4px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  background: linear-gradient(90deg, #3254a5 0%, #4cc0d0 100%);
  border-radius: 4px;
}

.servicesMain h4 {
  font-family: "DM Sans";
  font-weight: 700;
  font-size: 20px;
}

.servicesMain .container .row:nth-of-type(odd) {
  flex-direction: row-reverse;
}

.servicesMain .img-holder img {
  border-radius: 50px;
}

.termsPage h3 {
  margin-top: 16px;
}
.cta-terms {
  display: block;
  margin-top: 6px;
  font-size: 12px;
}
.cta-terms a {
  color: #011771;
}

.cta-terms a:hover {
  text-decoration: underline;
}

.payment-cards {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
  margin-top: 22px;
}

.payment-cards img {
  height: 32px;
}

/* ------------responsive----------- */

@media only screen and (max-width: 1600px) {
  .wrapperMain {
    min-height: calc(99vh - 100.86px);
  }

  .navbarMenu .navbar-nav a {
    font-size: 14px;
  }

  .arrowImg {
    margin-left: 19px;
    margin-top: 64px;
  }

  .heroText h3 {
    font-size: 26px;
  }

  /* .tabContentBody {
    height: 300px;
  } */

  .formWrapper {
    height: 512px;
  }

  .formWrapper.withslider {
    height: 240px;
  }

  header nav {
    height: 60px;
  }

  .navbar-brand img {
    height: 40px;
  }

  .heroText span {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 8px;
  }

  .heroText p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .heroText h3 {
    font-size: 22px;
  }

  .heroText h4 {
    font-size: 18px;
  }

  .btn-select {
    height: 44px;
    font-size: 14px;
  }

  .sliderWrapper {
    flex: 0 0 100px;
    max-width: 100px;
  }

  .sliderWrapper .mySwiper {
    height: 412px;
  }

  .caption {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
  }

  .orderDetail {
    padding: 30px 20px;
    border-radius: 12px;
  }

  .paymentCard {
    padding: 30px 30px 0px;
  }

  .orderHeader h3 {
    font-size: 18px;
    line-height: 1;
  }

  .cardSummary .formGroup {
    margin-bottom: 5px;
  }

  .cardSummary {
    padding: 10px 15px;
  }

  .cardAction {
    margin-top: 15px;
  }

  .summaryTotal li,
  .summaryDesc li {
    padding: 10px 10px 0;
  }

  .formWrapper {
    height: 281px;
  }

  .searchDropDown ul li .dropdown-item span.text {
    font-size: 14px;
  }

  .searchDropDown ul li .dropdown-item span.icon {
    width: 18px;
    height: 18px;
  }

  .searchDropDown ul li .dropdown-item {
    padding: 6px 4px;
  }

  .footerDropdown {
    padding: 8px 8px 4px;
  }

  .categoriesTabs .swiper-slide button h3 {
    font-size: 12px;
    line-height: 16px;
  }

  .cardMain {
    min-height: 599px;
  }

  .tabContent-header h3 {
    font-size: 14px;
  }

  .tabContent-header p {
    font-size: 14px;
  }

  .tabContentBody {
    height: 273px;
  }

  .cardMain .cardBody {
    padding: 8px 0;
  }

  .categoriesTabs .swiper-slide button h3 {
    -webkit-line-clamp: 2;
  }

  .timeList {
    min-width: 77px;
    width: 100%;
  }

  .sticky-whatsaap {
    bottom: 30px;
    right: 25px;
  }
}

@media (max-width: 1400px) {
  .AccomplishmentBox {
    left: 4px;
  }

  h3.OurYears::after {
    left: 103px;
  }

  .AccomplishmentBox.one {
    left: 191px;
  }

  h3.OurYears.one::after {
    left: 288px;
  }

  .AccomplishmentBox.two {
    left: 369px;
  }

  h3.OurYears.two::after {
    left: 473px;
  }

  .AccomplishmentBox.three {
    left: 545px;
  }

  h3.OurYears.three::after {
    left: 658px;
  }

  h3.OurYears.four::after {
    left: 843px;
  }

  .AccomplishmentBox.five {
    left: 915px;
  }

  h3.OurYears.five::after {
    left: 684px;
  }

  .AccomplishmentBox.four {
    left: 727px;
  }

  .sticky-whatsaap {
    right: 20px;
  }

  .cardProfile {
    min-height: 530px;
  }

  .wrapperMain {
    min-height: calc(100vh - 108.86px);
  }

  .orderDetailModal {
    height: 370px;
  }

  .ourEstablishment ul li {
    gap: 15px;
  }

  .ourEstablishment ul li .icon {
    width: 80px;
    height: 80px;
  }

  .ourEstablishment ul li .icon img {
    width: 38px;
  }

  .ourEstablishment ul {
    gap: 42px 16px;
  }

  .ourEstablishment ul li .icon:after {
    bottom: -71px;
  }

  .ourEstablishment ul li:nth-of-type(7) .icon:after,
  .ourEstablishment ul li:nth-of-type(6) .icon:after,
  .ourEstablishment ul li:nth-of-type(5) .icon:after {
    top: -34px;
  }

  .ourEstablishment ul:after {
    max-width: 1048px;
  }

  .ourEstablishment ul li .media-body h4 {
    font-size: 28px;
  }

  .ourEstablishment ul li .media-body p {
    font-size: 14px;
    line-height: 24px;
  }

  .partnership h2 {
    font-size: 46px;
  }

  .cardBenefits p {
    font-size: 16px;
  }

  .ourEstablishment ul:after {
    max-width: 989px;
  }
}

@media (max-width: 1360px) {
  .ourEstablishment ul:after {
    max-width: 1048px;
  }

  .heroText h1 {
    font-size: 28px;
  }

  .heroText span {
    font-size: 14px;
  }

  .heroText p {
    font-size: 12px;
  }

  .heroText h4 {
    font-size: 16px;
  }

  .heroText h3 {
    font-size: 18px;
  }

  .imgHolder img.carpool-img {
    max-width: 300px;
  }

  .arrowImg {
    margin-left: 60px;
    margin-top: 0;
    position: absolute;
  }

  .heroText {
    max-width: 500px;
  }

  .heroText .imgHolder {
    position: relative;
  }

  header .btnPrimary {
    font-weight: 500;
    font-size: 14px;
    padding: 8px 18px;
    height: 45px;
  }

  .navbar-brand img {
    height: 32px;
  }

  .topbar .address,
  .contactLinks a,
  .appLinks p {
    font-size: 12px;
  }

  .topbar {
    padding: 8px 0;
  }

  .sliderWrapper .mySwiper {
    height: 425px;
  }

  .btn-select {
    height: 40px;
    font-size: 12px;
  }

  .counterWizard .steps {
    font-size: 12px;
  }

  .cardMain {
    padding: 8px 8px;
  }

  .cardMain .cardBody {
    padding: 8px 0;
  }

  .title h3 {
    padding-bottom: 6px;
    margin-bottom: 6px;
  }

  .formWrapper {
    height: 342px;
  }

  .searchDropDown ul li .dropdown-item span.text {
    font-size: 12px;
  }

  .tabContentBody {
    height: 287px;
  }

  .btn-ellipses i {
    font-size: 20px;
  }

  .cardSaved .vehicleInfo {
    padding: 1px 5px;
  }

  .cardMain .cardBody {
    padding: 5px 0;
  }

  .our-partners h2 {
    font-size: 42px;
  }

  .number .img-holder span {
    top: 116px;
  }

  .regionalMain h2 {
    font-size: 42px;
  }
}

@media (max-width: 1200px) {
  header .navbar-expand-xl [class*="col-"] {
    width: auto;
    flex: 1;
    text-align: center;
  }

  .toggle-button {
    border: none;
    padding: 0;
    margin: 0;
    font-size: 24px;
    background: transparent !important;
    background-color: transparent !important;
    color: #000;
  }

  .navbarMenu {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999;
    overflow: hidden;
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s linear;
  }

  .overlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    z-index: 999;
    overflow: hidden;
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s linear;
  }

  .navbarMenu.show .overlay {
    opacity: 1;
    visibility: visible;
  }

  .navbarMenu .navbar-nav {
    background: #fff;
    max-width: 300px;
    width: 100%;
    position: fixed;
    overflow-y: auto;
    bottom: 0;
    top: 0;
    right: 0;
    padding: 0;
    transform: translateX(100%);
    transition: transform 0.3s linear;
    z-index: 999;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    border-radius: 16px 0px 0px 16px;
  }

  .navbarMenu.show {
    opacity: 1;
    visibility: visible;
  }

  .navbarMenu.show .navbar-nav {
    transform: translateX(0%);
  }

  .navbarMenu .navbar-nav a {
    margin: 8px 0;
    border-radius: 0;
    text-align: left;
    justify-content: flex-start;
  }

  .closeMenu {
    padding: 0;
    border: none;
    background: transparent;
  }

  header .menuHeader {
    justify-content: space-between;
  }

  header .menuHeader .brand {
    height: 24px;
  }

  header .menuHeader {
    padding: 16px 24px;
    border-bottom: 1px solid #ebebeb;
  }

  .menu-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 16px;
    flex: 1;
  }

  .menu-links a {
    width: 100%;
    margin: 0;
  }

  .topbar {
    background: transparent;
  }

  .appLinks {
    display: none;
  }

  .mobileContact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 16px;
  }

  .mobileContact li {
    margin: 0;
  }

  .navbarMenu .navbar-nav .mobileContact li .address,
  .navbarMenu .navbar-nav .mobileContact li a {
    padding: 0 0 !important;
    height: auto;
    margin: 6px 0;
    font-size: 13.3px;
    color: #7c868e;
    display: flex;
    gap: 8px;
  }

  .mobileContact .btn {
    color: #fff !important;
    border-radius: 100px !important;
    justify-content: center !important;
  }

  .mobileContact .appLinks {
    display: block;
    margin: 0 !important;
    width: 100%;
  }

  .mobileContact .appLinks p {
    color: #000;
  }

  .mobileContact .appLinks .app {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    margin: 12px 0;
  }

  .mobileContact .appLinks .app a {
    flex: 1;
    padding: 0 !important;
    margin: 0 !important;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.4),
      0px 12px 12px -6px rgba(0, 0, 0, 0.16);
  }

  .mobileContact .appLinks .app a img {
    width: 100%;
    height: auto !important;
  }

  .navbarMenu .navbar-nav a {
    margin: 0;
    border-radius: 100px;
  }

  .AccomplishmentBox.three {
    left: 0;
    bottom: 565px;
    width: 100%;
    height: 7em;
  }

  .AccomplishmentBox.three::after {
    left: 48.2%;
    bottom: 0;
    top: -30px;
  }

  .AccomplishmentBox.five {
    left: 0;
    bottom: 294px;
    width: 100%;
    height: 7em;
  }

  .AccomplishmentBox.five::after {
    left: 48.2%;
    bottom: 0;
    top: -30px;
  }

  .AccomplishmentBox.four {
    left: 0;
    width: 100%;
    height: 7em;
    top: auto;
    bottom: 24px;
  }

  .AccomplishmentBox.four::after {
    left: 48.2%;
    bottom: 0;
    top: -29px;
  }

  .AccomplishmentBox.two {
    top: auto;
    bottom: 835px;
    left: 0;
    width: 100%;
    height: 7em;
    padding: 1.875em 1.1em;
  }

  .AccomplishmentBox.two::after {
    bottom: auto;
    top: -29px;
    left: 48.2%;
  }

  .AccomplishmentBox.one {
    left: 0;
    top: 403px;
    bottom: auto;
    height: 7em;
  }

  .AccomplishmentBox.one::after {
    left: 48.2%;
    bottom: 0;
    top: -30px;
  }

  .AccomplishmentBox {
    width: 100%;
    height: 7em;
    top: 132px;
    left: 0;
    bottom: auto;
  }

  .AccomplishmentBox::after {
    left: 48.2%;
    bottom: 83px;
  }

  ul.AccomplishmentUl {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  ul.AccomplishmentUl li {
    width: 100%;
    height: 270px;
  }

  h3.OurYears::after {
    display: none;
  }

  h2.weAreHeading {
    font-size: 30px;
  }

  .ourVision h2.visionHeading {
    font-size: 28px;
  }

  p.contactContent {
    font-size: 16px;
  }

  .counterWizard .stepers li span {
    min-width: 83px;
  }

  .circlebg {
    height: inherit;
  }

  .wrapperMain {
    min-height: calc(100vh - 100.86px);
  }

  /* .sticky-whatsaap {
    bottom: 35px;
    right: 40px;
    width: 44px;
    height: 44px;
  } */

  .cardMain {
    min-height: 519px;
  }

  .tabContentBody {
    height: 214px;
  }

  .imgHolder img.arrowImg {
    max-width: 150px;
  }

  .arrowImg {
    margin-left: 0px;
    margin-top: 20px;
  }

  .sliderWrapper .mySwiper {
    height: 350px;
  }

  .heroText .imgHolder img:first-of-type {
    max-width: 300px;
    max-height: 400px;
  }

  .sticky-whatsaap {
    bottom: 20px;
    z-index: 999;
    left: auto;
    right: 20px;
  }

  .sticky-whatsaap img {
    height: 60px;
  }

  .selectedService {
    padding: 6px 20px;
  }

  .btn-user {
    pointer-events: none;
  }

  .rightLinks li:nth-child(2) {
    display: none;
  }

  .rightLinks li {
    margin: 0 25px;
  }

  .ourEstablishment ul li .media-body p {
    max-width: 190px;
  }

  .ourEstablishment ul {
    gap: 42px 12px;
  }

  .ourEstablishment ul li .icon:after {
    bottom: -74px;
  }

  .ourEstablishment ul li:nth-of-type(7) .icon:after,
  .ourEstablishment ul li:nth-of-type(6) .icon:after,
  .ourEstablishment ul li:nth-of-type(5) .icon:after {
    top: -34px;
  }

  .ourEstablishment ul:after {
    max-width: 976px;
  }

  .contactWrapper {
    padding-left: 50px;
    margin-left: 30px;
  }

  .secondaryHeader .card-hero .title h2 {
    font-size: 56px;
  }

  .secondaryHeader .toggle-button,
  .secondaryHeader .btn-user .name,
  .secondaryHeader .navbarMenu .navbar-nav a {
    color: #2b2b2b;
  }

  .our-clients ul {
    gap: 20px;
  }

  .card-vision .content p {
    font-size: 18px;
  }

  .ourEstablishment ul li .icon {
    width: 60px;
    height: 60px;
  }

  .ourEstablishment ul li .media-body h4 {
    font-size: 24px;
  }

  .ourEstablishment ul li .media-body p {
    max-width: 180px;
    font-size: 12px;
    line-height: 18px;
  }

  .ourEstablishment ul {
    flex-direction: column;
    padding-left: 50px;
  }

  .ourEstablishment ul li {
    text-align: left;
    z-index: 5;
    position: relative;
  }

  .ourEstablishment ul li:nth-of-type(2),
  .ourEstablishment ul li:nth-of-type(3) {
    flex-direction: row-reverse;
  }

  .ourEstablishment ul:after {
    max-width: 1px;
    width: 100px;
    height: 100%;
    left: 0;
    border-left: 1px solid dashed;
    border-style: dashed;
    border-color: #b1aca9;
  }

  .ourEstablishment ul:after {
    border-top: none;
    border-width: 1px;
  }

  .ourEstablishment ul li:first-of-type .icon:after {
    right: auto;
    left: -61px;
    bottom: auto;
    top: -15px;
  }

  .ourEstablishment ul li:nth-of-type(7) .icon:after,
  .ourEstablishment ul li .icon:after {
    bottom: auto;
    left: -61px;
    z-index: 5;
  }

  .ourEstablishment ul li:nth-of-type(6) .icon:after,
  .ourEstablishment ul li:nth-of-type(5) .icon:after {
    top: auto;
    left: -61px;
  }

  .ourEstablishment ul li:nth-child(4) {
    flex-direction: row-reverse;
  }
}

@media (max-width: 991px) {
  .AccomplishmentBox::after {
    left: 47.4%;
    bottom: 83px;
  }

  .AccomplishmentBox.one::after {
    left: 47.4%;
    bottom: 0;
    top: -30px;
  }

  .AccomplishmentBox.two::after {
    bottom: auto;
    top: -29px;
    left: 47.4%;
  }

  .AccomplishmentBox.three::after {
    left: 47.4%;
    bottom: 0;
    top: -30px;
  }

  .AccomplishmentBox.four::after {
    left: 47.4%;
    bottom: 0;
    top: -29px;
  }

  .awardWinning img.aboutBanner {
    max-width: 400px;
  }

  .heroText .imgHolder,
  .heroText p {
    display: none;
  }

  .heroText h3 {
    margin-bottom: 24px;
  }

  .heroText h1 {
    font-size: 26px;
    margin-bottom: 30px;
  }

  .sliderWrapper .mySwiper {
    height: 425px;
  }

  /* header .navbar-expand-lg [class*="col-"] {
    width: auto;
    flex: 1;
    text-align: center;
  } */

  /* .rightLinks li:nth-child(2) {
    display: none;
  } */
  /* 
  .rightLinks li {
    margin: 0 25px;
  } */

  .tabContentBody {
    height: 290px;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    flex: 0 0 14.2%;
    width: 14.2%;
    margin: 0;
  }

  .react-datepicker__day-names,
  .react-datepicker__week {
    width: 100%;
  }

  .timeList {
    width: auto;
    flex: 0 0 200px;
  }

  .react-datepicker__day {
    height: 56px;
  }

  .orderDetailModal {
    height: auto;
  }

  .orderContent {
    overflow: hidden;
  }

  .infoDesc h5 {
    padding: 0 12px;
  }

  .infoDesc .imgHolder {
    padding-left: 0;
  }

  .sticky-whatsaap {
    bottom: 20px;
    z-index: 999;
    left: auto;
    right: 20px;
  }

  .sticky-whatsaap img {
    height: 60px;
  }

  .caption {
    margin-bottom: 52px;
  }

  .orderHeader {
    margin-bottom: 20px;
  }

  .orderDetail {
    padding-bottom: 0;
  }

  .paymentCard {
    padding: 0;
  }

  .paymentWrapper {
    padding: 10px 20px;
  }

  .wrapperCart {
    padding: 10px 0 0;
    border-top: 1px solid #e6ecf7;
  }

  .cardAction {
    padding: 10px 20px;
  }

  .trackOrder {
    padding: 50px 30px;
  }

  .orderId img {
    max-width: 389px;
  }

  .orderId h3 {
    font-size: 28px;
  }

  .orderId p {
    font-size: 22px;
  }

  .orderId .id {
    margin: 8px 0 0;
  }

  .trackOrder img {
    max-width: 211px;
  }

  .trackOrder p {
    font-size: 16px;
  }

  .trackOrder h4 {
    font-size: 18px;
  }

  .trackOrder ul li img {
    max-width: 140px;
    height: auto;
  }

  .trackOrder .btnSecondary {
    max-width: 289px;
    font-size: 14px;
  }

  .orderId img {
    max-width: 306px;
  }

  .dropdownTitle h3 {
    font-size: 18px;
  }

  .dropdownTitle {
    padding: 8px 16px;
  }

  .cartFooter {
    padding: 6px 16px;
  }

  .profileMain .title {
    font-size: 32px;
  }

  .cardProfile .uploadFile + label {
    margin: 0 auto;
    display: block;
  }

  .tableWrapper .table tr th,
  .tableWrapper .table tr td {
    padding: 8px;
    font-size: 12px;
  }

  .tableWrapper .table tr td .price,
  .tableWrapper .table tr td .service p,
  .tableWrapper .table tr td .service h4,
  .tableWrapper .table tr td .date,
  .tableWrapper .table tr td .time {
    font-size: 12px;
  }

  .tableWrapper .table {
    width: 960px;
  }

  .searchHistory .form-control {
    font-size: 14px;
  }

  .titlePage h3 {
    font-size: 28px;
  }

  .policyCntainer h2 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  .policyCntainer h3 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .policyCntainer p {
    font-size: 14px;
    padding-left: 15px;
    /* Add left padding for indentation */
  }

  .policyCntainer .policyBox {
    padding: 0 30px;
  }

  .contactWrapper {
    margin-left: 0;
    margin-top: 40px;
  }

  .footer-content {
    margin-bottom: 30px;
  }

  .secondaryHeader .card-hero .title h2 {
    font-size: 48px;
  }

  .whyChoose .title {
    margin-bottom: 30px;
  }

  .profileWhoWe .content {
    max-width: 100%;
    margin-top: 30px;
  }

  .value .img-holder {
    margin-bottom: 30px;
  }

  .value .img-holder img {
    height: auto;
  }

  .ourEstablishment ul li:nth-child(4) {
    flex-direction: row-reverse;
  }

  .number .img-holder span {
    top: 130px;
  }

  .regionalMain h2 {
    border-radius: 0;
    margin-bottom: 30px;
  }

  .partnership h2 {
    margin-bottom: 30px;
  }

  .partnership h2 {
    border-radius: 24px;
  }

  .partnership-collaborate .content {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .regionalMain .img-holder img {
    max-width: 500px;
  }

  .regionalMain .img-holder {
    padding: 0;
    margin: 0 auto;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .AccomplishmentBox.five {
    left: -6px;
    bottom: 294px;
    width: 100%;
    height: 7em;
  }

  header .btnPrimary {
    font-weight: 500;
    font-size: 12px;
    padding: 8px 10px;
    height: 35px;
    width: max-content;
  }

  header .btnPrimary i {
    margin-right: 4px;
    line-height: 0;
    font-size: 22px;
  }

  .AccomplishmentBox::after {
    left: 46.4%;
    bottom: 83px;
  }

  .AccomplishmentBox.one::after {
    left: 46.4%;
    bottom: 0;
    top: -30px;
  }

  .AccomplishmentBox.two::after {
    bottom: auto;
    top: -29px;
    left: 46.4%;
  }

  .AccomplishmentBox.three::after {
    left: 46.4%;
    bottom: 0;
    top: -30px;
  }

  .AccomplishmentBox.four::after {
    left: 46.4%;
    bottom: 0;
    top: -29px;
  }

  .policyCntainer .policyBox {
    padding: 0 20px;
  }

  .topbar {
    display: none;
  }

  .topbar.topbar-pre {
    display: block;
  }

  .serviceMain {
    flex-direction: column;
    display: block;
    padding: 8px 20px;
  }

  .sliderWrapper {
    flex: inherit;
    max-width: 100%;
    width: 100%;
  }

  .cardTypes {
    height: auto;
  }

  .sliderWrapper .mySwiper {
    height: auto;
  }

  .sliderHolder {
    display: block;
    padding: 0 24px;
    position: relative;
    margin: 13px 0;
  }

  .sliderHolder .custom-navigation {
    position: absolute;
    top: 50%;
  }

  .cardInner {
    padding: 0;
  }

  .cardMain {
    padding: 0;
  }

  .cardHeader {
    padding: 8px 20px;
  }

  .cardHeader h3 {
    font-size: 14px;
    margin: 0;
  }

  .counterWizard .steps {
    margin-right: 6px;
    flex: 0 0 40px;
  }

  .serviceMain .media-body {
    width: calc(100% - 0px);
  }

  .sliderWrapper .custom-navigation.next {
    right: -5px;
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  .sliderWrapper .custom-navigation.prev {
    left: 8px;
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  .categoriesTabs {
    padding: 0;
  }

  .categoriesTabs .swiper-slide button {
    padding: 8px;
  }

  .heroText h1 {
    font-size: 18px;
    line-height: 1;
  }

  .heroText .imgHolder,
  .heroText p {
    display: none;
  }

  .heroText {
    padding: 0 15px;
  }

  .heroText h1 {
    margin: 0;
  }

  .heroText h4 {
    margin-top: 79px;
    font-size: 14px;
    margin-bottom: 4px;
  }

  .heroText h3 {
    font-size: 16px;
    margin-bottom: 12px;
  }

  header .brand img {
    height: 24px;
  }

  .wrapperMain {
    min-height: calc(100vh - 0px);
  }

  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 100px;
  }

  .cardTypes {
    display: flex;
    flex-direction: row;
    padding: 8px;
    gap: 8px;
  }

  .cardTypes h4 {
    flex: 1;
    text-align: left;
    margin: 0;
  }

  .cardTypes .icon {
    width: 32px;
    height: 32px;
    margin: 0;
  }

  .circlebg {
    position: absolute;
    right: 0;
    text-align: center;
    margin: 0 auto;
    width: 731px;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 27px;
  }

  .cardMain .cardBody.stepTwo {
    padding: 8px 20px;
  }

  .selectMenu .dropdown-item {
    font-size: 12px;
    padding: 4px;
    gap: 4px;
  }

  .cardMain .cardBody.thirdStep {
    padding: 8px 20px;
  }

  .text-truncate {
    width: 200px;
  }

  .sticky-whatsaap {
    z-index: 999;
  }

  .sticky-whatsaap img {
    width: 40px;
    height: 40px;
  }

  .sticky-whatsaap {
    bottom: 0;
    right: -17px;
    left: auto;
  }

  .homeMain {
    padding: 30px 0 0;
  }

  .counterWizard .stepers li span {
    min-width: 70px;
  }

  .caption {
    margin-bottom: 28px;
  }

  .orderDetail .brandName {
    height: 29px;
    display: block;
    margin: 0 auto 20px;
  }

  .orderDetail {
    padding: 16px 24px;
  }

  .orderHeader h3 {
    font-size: 14px;
  }

  .orderHeader {
    margin-bottom: 0;
    padding: 0 0 16px;
    margin-bottom: 24px;
    border-bottom: 1px solid #ebebeb;
  }

  .orderContent {
    border-radius: 8px;
    background: var(--white, #fff);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;
  }

  .mediaorderCard .media-body h4 {
    font-size: 12px;
  }

  .mediaorderCard .media-body h4 {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }

  .mediaorderCard .media-body h4 i img {
    max-width: 100%;
  }

  .mediaorderCard .media-body h4 i {
    max-width: 24px;
  }

  .mediaorderCard .media-body .address {
    align-items: flex-start;
  }

  .mediaorderCard .media-body .price {
    font-size: 12px;
    margin-top: 10px;
  }

  .mediaorderCard {
    margin: 0;
  }

  .vehicleinfo .icon,
  .serviceInfo .icon img {
    width: 24px;
    height: 24px;
  }

  .vehicleinfo .icon,
  .serviceInfo .icon {
    width: 24px;
    height: 24px;
  }

  .vehicleinfo .media-body,
  .serviceInfo .media-body {
    padding: 0 8px;
    font-size: 12px;
  }

  .collapseContent h4 {
    font-size: 14px;
  }

  .infoDesc.show,
  .collapseContent.show {
    padding-top: 8px;
  }

  .footerCollapseContent {
    flex-direction: column;
    align-items: flex-start;
  }

  .infoDesc {
    padding: 0;
  }

  .vehicleinfo .media-body h3 {
    font-size: 12px;
  }

  .footerCollapseContent .media-body {
    padding: 0 0;
  }

  .serviceInfo .media,
  .footerCollapseContent {
    padding: 12px;
  }

  .vehicleinfo .v-color {
    width: 12px;
    height: 12px;
  }

  .orderDetailModal {
    height: auto;
  }

  .orderContent {
    overflow: hidden;
  }

  .orderId {
    padding: 60px 20px;
  }

  .orderId h3 {
    font-size: 24px;
  }

  .trackOrder h4,
  .orderId p {
    font-size: 18px;
    line-height: 1.4;
    text-align: center;
  }

  .trackOrder p,
  .orderId .id {
    font-size: 14px;
  }

  .trackOrder {
    padding: 50px 20px;
  }

  .trackOrder img {
    max-width: 109px;
  }

  /* .trackOrder ul li img {
    max-width: inherit;
  } */

  .trackOrder .btnSecondary {
    font-size: 14px;
  }

  .cstTableHistory {
    height: calc(100% - 0px);
  }

  .cardHistoryRecord .record .recordId {
    font-size: 10px;
  }

  .tableWrapper {
    background: transparent;
  }

  .cardHistoryRecord .service h4 {
    font-size: 12px;
  }

  .cardHistoryRecord .service {
    padding: 0 0 8px;
  }

  .cardHistoryRecord .service .price {
    font-weight: 600;
    font-size: 12px;
  }

  .cardHistoryRecord .cardFooterHistory .locationHistory .location {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #54595e;
  }

  .titlePage h3 {
    text-align: center;
    font-size: 28px;
  }

  .customModal .form-card img {
    max-width: 127px;
  }

  .customModal .form-card h3 {
    font-size: 16px;
  }

  .timeList {
    width: auto;
    flex: 0 0 150px;
  }

  .react-datepicker__day {
    height: 40px;
  }

  .orderId img {
    max-width: 190px;
  }

  .mediaCard {
    padding: 8px;
  }

  .formRow {
    flex-direction: column;
    gap: 0;
  }

  .formRow .cst-select {
    flex: inherit !important;
  }

  .secondaryHeader .card-hero {
    padding: 100px 50px;
  }

  .formRow .cst-select:first-of-type {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .our-partners ul {
    border-radius: 20px;
    justify-content: center;
  }

  .our-clients ul {
    flex-wrap: wrap;
    border-radius: 20px;
    justify-content: center;
  }

  .card-vision .content p {
    font-size: 16px;
  }

  .regionalMain .img-holder img {
    max-width: 350px;
  }

  .our-partners ul:first-of-type li,
  .our-partners ul li {
    flex: inherit;
    max-width: auto;
  }

  .our-partners ul li img {
    max-height: 80px;
  }

  .our-partners {
    padding: 60px 20px;
  }

  .our-partners ul li img {
    height: 100px;
  }
}

@media (max-width: 575px) {
  .AccomplishmentBox::after {
    left: 46.6%;
    bottom: 83px;
  }

  .AccomplishmentBox.one::after {
    left: 46.6%;
    bottom: 0;
    top: -30px;
  }

  .AccomplishmentBox.two::after {
    bottom: auto;
    top: -29px;
    left: 46.6%;
  }

  .AccomplishmentBox.three::after {
    left: 46.6%;
    bottom: 0;
    top: -30px;
  }

  .AccomplishmentBox.four::after {
    left: 46.6%;
    bottom: 0;
    top: -29px;
  }

  h2.weAreHeading {
    font-size: 22px;
  }

  .ourVision h2.visionHeading {
    font-size: 20px;
  }

  .btn-shoping + .dropdown-menu {
    min-width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    margin: 0 !important;
    border-radius: 0;
    display: block;
    transform: translateX(100%);
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
  }

  .btn-shoping + .dropdown-menu.show {
    transform: translateX(0%);
  }

  .cardBody.youcart {
    flex: 1;
  }

  .cardBody.youcart {
    padding: 12px;
  }

  .circlebg {
    position: absolute;
    right: 0;
    text-align: center;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 27px;
    width: 700px;
  }

  .paymentMethod .flex-1 h4 {
    font-size: 14px;
  }

  .paymentMethod .flex-1 p {
    font-size: 12px;
  }

  .brandCard li img {
    height: 15px;
  }

  .cardSummary .formGroup .btn,
  .cardSummary .formGroup .form-control {
    font-size: 12px;
  }

  .summaryTotal li .media-body,
  .summaryDesc li .media-body {
    font-size: 10px;
  }

  .cardAction .btn,
  .summaryTotal li .price,
  .summaryDesc li .price {
    font-size: 12px;
  }

  .cardAction .btnPrimary {
    flex: 1;
  }

  .orderId img {
    max-width: 167px;
  }

  .trackOrder .btnSecondary {
    max-width: 100%;
  }

  .searchHistory .form-control {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .timeDate {
    flex-direction: column;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    height: 33px;
    width: 33px;
  }

  .timeList {
    border: 1px solid var(--beerus);
    border-left: 1px solid var(--beerus);
    border-radius: 0 0 16px 16px;
    margin: -1px 0 0;
    max-width: 287px;
  }

  .react-datepicker {
    border-radius: 16px 16px 0px 0px;
    max-width: 287px;
  }

  .react-datepicker__navigation {
    top: 6px;
  }

  .formWrapper .row {
    margin: 0 -4px;
  }

  .formWrapper .row [class*="col-"] {
    padding: 0 4px;
  }

  .ourEstablishment ul li:nth-of-type(2),
  .ourEstablishment ul li:nth-of-type(3),
  .ourEstablishment ul li {
    flex-direction: column;
  }

  .ourEstablishment ul li:nth-of-type(2),
  .ourEstablishment ul li:nth-of-type(3) {
    flex-direction: column-reverse;
  }

  .ourEstablishment h2 {
    font-size: 32px;
    margin-bottom: 60px;
  }

  .secondaryHeader .card-hero {
    padding: 120px 30px 50px;
  }

  .secondaryHeader .card-hero .title h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }

  .secondaryHeader .card-hero .title span {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .whoWeAre {
    padding: 70px 0;
  }

  .whoWeAre .row .row {
    margin: 0 -5px;
  }

  .whoWeAre .row .row [class*="col-"] {
    padding: 0 5px;
  }

  .whoWeAre .img-holder {
    margin-bottom: 10px !important;
  }

  .whoWeAre .img-holder:last-of-type {
    margin-bottom: 0 !important;
  }

  .whoWeAre .content h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }

  .whoWeAre .content p {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .ourEstablishment {
    padding: 60px 20px;
  }

  .whyChoose {
    padding: 70px 0;
  }

  .contactUs {
    padding: 70px 0;
  }

  .contactUs .form-control {
    min-height: 48px;
  }

  .contactWrapper {
    padding-left: 20px;
  }

  footer .copyrights p {
    padding: 20px 0;
  }

  .footer {
    padding: 35px 0;
  }

  .secondaryHeader .card-hero .title a {
    font-size: 14px;
    height: 40px;
    max-width: 203px;
  }

  .regionalMain .img-holder img {
    max-width: 100%;
  }

  .partnership-collaborate h2 {
    font-size: 42px;
  }

  .heroProfile .topVector .icon {
    height: 25px;
  }

  .heroProfile {
    padding: 40px 0;
  }

  .heroMain .logo {
    padding-top: 50px;
  }

  .ourEstablishment ul li:nth-child(4) {
    flex-direction: column-reverse;
  }

  .our-clients h2 {
    font-size: 42px;
  }

  .our-clients {
    padding: 50px 0;
  }

  .partnership-collaborate {
    padding: 50px 0;
  }

  .our-clients ul {
    padding: 10px;
  }

  .value {
    padding: 50px 0;
  }
}

@media (max-width: 400px) {
  .AccomplishmentBox::after {
    left: 44.4%;
    bottom: 83px;
  }

  .AccomplishmentBox.one::after {
    left: 44.4%;
    bottom: 0;
    top: -30px;
  }

  .AccomplishmentBox.two::after {
    bottom: auto;
    top: -29px;
    left: 44.4%;
  }

  .AccomplishmentBox.three::after {
    left: 44.4%;
    bottom: 0;
    top: -30px;
  }

  .AccomplishmentBox.four::after {
    left: 44.4%;
    bottom: 0;
    top: -29px;
  }

  .circlebg {
    width: 524px;
  }

  .titlePage .btn {
    max-width: 100%;
    width: 100%;
  }

  button.delte-servics.active span {
    display: none;
  }
}

.mediaTitle {
  flex: 1;
}

.timeListScroll {
  padding: 5px 8px;
  max-height: 313px;
  overflow-y: auto;
}

.timeListScroll p {
  font-size: 12px;
  padding: 36px 0 0;
  font-weight: 500;
}

.news_modal .btn-close {
  background: linear-gradient(90deg, #3151a4 0%, #4dc3d2 100%);
  padding: 0;
  margin: 0;
  position: absolute;
  opacity: 1;
  z-index: 999;
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  right: -17px;
  top: -17px;
}

.news_modal .modal-header {
  padding: 0;
  border: none;
}

.news_modal .modal-body {
  padding: 0;
}

@media (max-width: 575px) {
  .news_modal .btn-close {
    right: 0;
  }
}

.imgApp {
  display: flex;
  text-align: left;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 560px;
  margin: auto;
}

.imgApp ul {
  text-align: left;
}

.imgApp .playIcon {
  max-width: 130px;
}

.imgApp ul li {
  margin-bottom: 10px;
}

.mobile-App {
  max-width: 235px;
  width: 100%;
}

@media (max-width: 1340px) {
  .imgApp ul li img {
    max-width: 250px;
  }

  .imgApp .playIcon {
    max-width: 180px;
  }
}

@media (max-width: 575px) {
  .imgApp {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  .imgApp .h-img {
    width: 100%;
    text-align: center;
  }

  .mobile-App {
    max-width: 250px;
  }
}
